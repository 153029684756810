import { createSlice } from "@reduxjs/toolkit";

import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { darkTheme, lightTheme } from "../../components/styles/theme/theme";
import { exchangerSettingsTypes, themeColor } from "../../utils/consts";
import { defaultSiteConfig } from "../../utils/defaultSiteConfig";
import { fetchThemeConfig } from "./asyncThemeSlice";

const colorScheme = window.matchMedia("(prefers-color-scheme: dark)").matches;

const examinationDefaultTheme = colorScheme ? themeColor.DARK : themeColor.LIGHT;

const setThemeModeByDefault = () => {
  if (localStorage.getItem("theme")) {
    return localStorage.getItem("theme") === themeColor.LIGHT ? lightTheme : darkTheme;
  }

  return colorScheme ? darkTheme : lightTheme;
};

const updateThemeColors = (theme, payload) => {
  const updatedPrimary = payload
    .filter((item) => item.type === exchangerSettingsTypes.COLOR && item.name.startsWith("primary"))
    .reduce((acc, item) => {
      const key = item.name.replace("primary", "").toLowerCase();

      if (Object.prototype.hasOwnProperty.call(theme.primary, key)) {
        acc[key] = item.value;
      }

      return acc;
    }, {});

  const headerBackgroundColorItem = payload.find((item) => item.name === "headerBackgroundColor");

  return {
    ...theme,
    primary: {
      ...theme.primary,
      ...updatedPrimary,
    },
    ...(headerBackgroundColorItem && { headerBackgroundColor: headerBackgroundColorItem.value }),
  };
};

const updateSiteConfig = (config, payload) => {
  let updatedConfig = { ...config };

  if (Array.isArray(payload)) {
    payload.forEach((item) => {
      if (item.type === exchangerSettingsTypes.INPUT) {
        updatedConfig[item.name] = item.value;
      } else if (item.type === exchangerSettingsTypes.IMAGE && item.mediaObjectUrl) {
        updatedConfig[item.name] = item.mediaObjectUrl;
      }
    });
  }

  return updatedConfig;
};

const initialState = {
  theme: localStorage.getItem("theme") || examinationDefaultTheme,
  themeMode: setThemeModeByDefault(),
  error: null,
  loading: true,
  siteConfig: defaultSiteConfig,
};

const themeSlice = createSlice({
  name: "themeSlice",
  initialState,
  reducers: {
    handleChangeTheme: (state) => {
      const examinationCurrentTheme = state.theme === themeColor.LIGHT;

      state.theme = examinationCurrentTheme ? themeColor.DARK : themeColor.LIGHT;

      state.themeMode = examinationCurrentTheme ? darkTheme : lightTheme;

      localStorage.setItem("theme", state.theme);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchThemeConfig.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchThemeConfig.fulfilled, (state, action) => {
      state.siteConfig = updateSiteConfig(defaultSiteConfig, action.payload);
      state.lightTheme = updateThemeColors(lightTheme, action.payload);
      state.darkTheme = updateThemeColors(darkTheme, action.payload);
      state.themeMode = state.theme === themeColor.LIGHT ? state.lightTheme : state.darkTheme;
      state.loading = false;
    });
    builder.addCase(fetchThemeConfig.rejected, (state, action) => {
      closableNotification(action.payload, "error");
      state.loading = false;
    });
  },
});

export const { handleChangeTheme } = themeSlice.actions;

export default themeSlice.reducer;
