import React from "react";

import { checkPermission } from "../../../utils/customFunc/checkPermission";
import withPermissionCheckComponent from "../../../utils/customHOC/withPermissionCheckComponent";
import CustomLink from "../../elements/customLink/CustomLink";
import SidebarItem from "./SidebarItem";
import sidebarRoutes from "./sidebarRoutes";

const SidebarLinkList = ({ setAnimateOpen, animateOpen, t, setToggled, collapsed, toggled }) => {
  return sidebarRoutes.map((rout, index) => {
    if (rout?.checkFullAccess) {
      const isHaveFullAccess = checkPermission(rout?.permission);
      if (!isHaveFullAccess) {
        return;
      }
    }

    const SidebarItemWithPerm = withPermissionCheckComponent(SidebarItem, rout?.permission);

    return (
      <SidebarItemWithPerm
        key={index}
        animateOpen={animateOpen}
        setAnimateOpen={setAnimateOpen}
        // index={index}
        collapsed={collapsed}
        as={CustomLink}
        to={rout?.path}
        icon={rout.icon}
        title={rout.title}
        perform={rout.perform}
        subLink={rout?.subLink}
        subRelation={rout?.subRelation}
        toggled={toggled}
        setToggled={setToggled}
        t={t}
      />
    );
  });
};

export default SidebarLinkList;
