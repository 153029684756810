import { createAsyncThunk } from "@reduxjs/toolkit";

import { transformFilterString } from "../../../components/pairUnit/main/helpers";
import axiosInstance from "../../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";

const fetchPairUnits = createAsyncThunk(
  "pairUnitSlice/fetchPairUnits",
  async ({ filterUrl }, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/pair-units${filterUrl}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data["hydra:member"],
        paginationInfo: {
          totalPageCount: response.data["hydra:totalPageCount"],
          totalItems: response.data["hydra:totalItems"],
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data);
    }
  }
);

const fetchUserPairUnits = createAsyncThunk(
  "pairUnitSlice/fetchUserPairUnits",
  async ({ filterUrl }, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/user-pair-units${transformFilterString(filterUrl)}`,
        userAuthenticationConfig()
      );
      const data = Object.values(response.data.data);

      return {
        data,
        paginationInfo: {
          totalPageCount: response.data.totalPageCount,
          totalItems: response.data.totalItems,
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data);
    }
  }
);

const changePairUnitActivity = createAsyncThunk(
  "pairUnitSlice/changePairUnitActivity",
  async ({ id, isActive }, thunkAPI) => {
    try {
      const response = await axiosInstance.put(
        `/api/pair-units/${id}`,
        { isActive: !isActive },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeMyExchangerPairUnitActivity = createAsyncThunk(
  "pairUnitSlice/changeMyExchangerPairUnitActivity",
  async ({ id, isActive }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/user-pair-units/fill",
        { isActive, pairUnits: [id] },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeMyExchangerPairUnit = createAsyncThunk(
  "pairUnitSlice/changeMyExchangerPairUnit",
  async ({ id, ...data }, thunkAPI) => {
    try {
      const response = await axiosInstance.put(
        `/api/user-pair-units/${id}`,
        { ...data },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeMyExchangerInputData = createAsyncThunk(
  "pairUnitSlice/changeMyExchangerCommission",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await axiosInstance.patch(
        `/api/user-fees/${id}`,
        data,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const fetchMyExchangerAvailableServices = createAsyncThunk(
  "pairUnitSlice/fetchMyExchangerAvailableServices",
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance("/api/rate-services", userAuthenticationConfig());

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeMyExchangerAvailableServices = createAsyncThunk(
  "pairUnitSlice/changeMyExchangerAvailableServices",
  async ({ id, baseCurrencyRateServiceName }, thunkAPI) => {
    try {
      const response = await axiosInstance.patch(
        `/api/user-pair-units/${id}/change-rate-service`,
        { baseCurrencyRateServiceName },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

export {
  changeMyExchangerAvailableServices,
  changeMyExchangerInputData,
  changeMyExchangerPairUnit,
  changeMyExchangerPairUnitActivity,
  changePairUnitActivity,
  fetchMyExchangerAvailableServices,
  fetchPairUnits,
  fetchUserPairUnits,
};
