import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

import getUserInfo from "../getUserInfo";

export const usePermissionGuard = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    const userPermissions = getUserInfo()?.endpoints;

    if (!userPermissions) {
      return;
    }

    const elementsWithPermissions = document.querySelectorAll("[data-permission]");

    elementsWithPermissions.forEach((domElement) => {
      const permissions = domElement.dataset.permission?.split(",");

      if (permissions) {
        const hasPermission = permissions.some((domPermission) =>
          userPermissions.some(
            (userPermission) =>
              userPermission.name === domPermission && userPermission.hasFullAccess
          )
        );

        if (!hasPermission) {
          domElement.remove();
        }
      }

      // if (permissions && !permissions.some(domPermission => userPermissions.includes(domPermission))) {
      //   domElement.remove();
      // }
    });
  }, [location.pathname]);
};
