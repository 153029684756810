import { createSlice } from "@reduxjs/toolkit";

import { errorsParser } from "../../utils/errorsParser";
import { fetchOrders } from "./asyncOrdersSlice";

const initialState = {
  orders: [],
  error: null,
  loading: true,
  paginationInfo: {
    totalItems: null,
    totalPageCount: null,
    itemsPerPage: null,
  },
};

const ordersSlice = createSlice({
  name: "ordersSlice",
  initialState,
  reducers: {
    setPaginationInfo: (state, action) => {
      state.paginationInfo = action.payload;
    },
    clearOrdersStore: (state) => {
      state.orders = [];
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrders.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchOrders.fulfilled, (state, action) => {
      state.orders = action.payload.data;
      state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
      state.loading = false;
    });
    builder.addCase(fetchOrders.rejected, (state, action) => {
      errorsParser(action.payload, true);
      state.loading = false;
    });
  },
});

export const { setPaginationInfo, clearOrdersStore } = ordersSlice.actions;

export default ordersSlice.reducer;
