import styled from "styled-components";

import { StyledSpan, StyledTextRegular } from "../../styles/styledTypography";

export const StyledSidebar = styled.div`
  height: calc(100% - 60px);
  padding: 24px 0 44px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2px;
  transition: background-color 0.3s ease-in-out;

  //& > :last-child {
  //  margin-top: auto;
  //}

  @media screen and (max-width: 992px) {
    height: 100%;
    padding: 66px 0 16px;
    background-color: ${({ theme }) => theme.neutral.white};
  }
`;

export const StyledSeparator = styled.div`
  margin: 5px 15px 5px 15px;
  height: 1px;
  background-color: ${({ theme }) => theme.neutral.fourth};
  z-index: 10;
`;

export const StyledSidebarSiteLinks = styled.div``;

export const StyledSidebarItem = styled.div`
  padding: 0 24px;
  cursor: pointer;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;

  .active,
  .active_sub-link {
    ${StyledSpan},
    ${StyledTextRegular} {
      color: ${({ theme }) => theme.primary.first};
    }
  }

  .active {
    background-color: ${({ theme }) => theme.primary.third};
  }

  @media screen and (max-width: 992px) {
    padding: 0 16px;
  }
`;

export const StyledSidebarLink = styled.div`
  width: 100%;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  border: none;
  border-radius: 12px;
  outline: none;
  transition: all 0.3s ease;

  ${({ collapsed }) =>
    collapsed &&
    `
    ${StyledTextRegular} {
       display: none;
    }
  `}

  ${StyledSpan} {
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.neutral.third};
  }

  &:hover {
    ${StyledSpan}, ${StyledTextRegular} {
      color: ${({ theme }) => theme.primary.first};
    }
  }

  @media screen and (max-width: 768px) {
    ${StyledTextRegular} {
      display: block;
    }
  }
`;

export const StyledSidebarSubLinkList = styled.div`
  height: ${({ isOpen, bodyHeight }) => (isOpen ? `${bodyHeight}px` : "0")};
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
  transition: ${({ animateOpen }) => animateOpen && "all 0.3s ease"};

  @media screen and (max-width: 768px) {
    height: ${({ isOpen, bodyHeight }) => (isOpen ? `${bodyHeight}px` : "0")};
  }
`;

export const StyledSidebarSubLinkItem = styled.div`
  padding: 10px 0 10px 15px;
  display: flex;
  align-items: center;
  gap: 28px;
  cursor: pointer;

  border-radius: 12px;

  ${({ active, theme }) => active && `background-color: ${theme.primary.third};`}

  ${StyledTextRegular} {
    color: ${({ theme, active }) => (active ? theme.primary.first : theme.neutral.first)};
  }

  &:before {
    content: "●";
    display: block;
    border-radius: 50%;
    color: ${({ theme, active }) => (active ? theme.primary.first : theme.neutral.fourth)};
  }

  &:hover {
    ${StyledTextRegular} {
      color: ${({ theme }) => theme.primary.first};
    }

    &:before {
      color: ${({ theme }) => theme.primary.first};
    }
  }
`;
