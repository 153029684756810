import { createSlice } from "@reduxjs/toolkit";

import { fetchPaymentSystemReducers } from "./paymentSystemsReducers";

const initialState = {
  paymentSystem: [],
  loading: true,
};

const paymentSystemSlice = createSlice({
  name: "paymentSystemSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchPaymentSystemReducers(builder);
  },
});

export default paymentSystemSlice.reducer;
