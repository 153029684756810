import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";

const fetchTransactionDetails = createAsyncThunk(
  "transactionDetails/fetchTransactionDetails",
  async ({ id, exchangerId }, thunkAPI) => {
    const params = exchangerId ? `?exchangerId=${exchangerId}` : "";

    try {
      const response = await axiosInstance(
        `/api/invoices/${id}${params}`,
        userAuthenticationConfig()
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

export { fetchTransactionDetails };
