import { createSlice } from "@reduxjs/toolkit";

import { defaultItemsPerPage } from "../../../utils/consts";
import { geItemsPerPage } from "../../../utils/customFunc/getItemsPerPage";
import {
  changeAvailableServicesReducer,
  changeExchangerPairUnitActivityReducer,
  changeExchangerPairUnitInputDataReducer,
  changeExchangerPairUnitReducer,
  fetchAvailableServicesReducer,
  fetchExchangerPairUnitsReducer,
  fetchExchangerPairUnitsTabsReducer,
} from "./pairUnitReducers";

const initialState = {
  pairUnits: [],
  error: null,
  loading: true,
  pairUnitsTab: {
    list: [],
    error: null,
    loading: true,
  },
  changeActivityState: {
    loading: false,
  },
  changeTabState: {
    loading: false,
  },
  changeDataState: {
    loading: false,
  },
  changeAvailableServices: {
    loading: false,
  },
  activeMultiple: [],
  availableServices: [],
  paginationInfo: {
    totalItems: null,
    totalPageCount: null,
    itemsPerPage: null,
  },
};

const exchangerPairUnitSlice = createSlice({
  name: "exchangerPairUnitSlice",
  initialState,
  reducers: {
    setPaginationInfo: (state, action) => {
      state.paginationInfo = action.payload;
    },
    clearExchangerPairUnitStore: (state) => {
      state.pairUnits = [];
      state.loading = true;
      state.error = null;
      state.availableServices = [];
    },
    setAllMultipleId: (state) => {
      if (state.activeMultiple.length === state.pairUnits.length) {
        state.activeMultiple = [];
        return;
      }

      state.activeMultiple = state.pairUnits.map((item) => item.id);
    },
    setItemInMultipleArray: (state, action) => {
      if (!action.payload.isActive) {
        state.activeMultiple.push(action.payload.id);
      } else {
        state.activeMultiple = state.activeMultiple.filter((item) => item !== action.payload.id);
      }
    },
  },
  extraReducers: (builder) => {
    fetchExchangerPairUnitsReducer(builder);
    changeExchangerPairUnitActivityReducer(builder);
    fetchExchangerPairUnitsTabsReducer(builder);
    changeExchangerPairUnitReducer(builder);
    changeExchangerPairUnitInputDataReducer(builder);
    fetchAvailableServicesReducer(builder);
    changeAvailableServicesReducer(builder);
  },
});

export const {
  setPaginationInfo,
  clearExchangerPairUnitStore,
  setAllMultipleId,
  setItemInMultipleArray,
} = exchangerPairUnitSlice.actions;

export default exchangerPairUnitSlice.reducer;
