import { createSlice } from "@reduxjs/toolkit";

import {
  createServerReducer,
  fetchServersReducer,
  updateServerLimitReducer,
} from "./serversReducers";

const initialState = {
  servers: [],
  error: null,
  loading: true,
  createServer: {
    error: null,
    loading: false,
  },
  paginationInfo: {
    totalItems: null,
    totalPageCount: null,
    itemsPerPage: null,
  },
};

export const SLICE_NAME = "serversSlice";

const serversSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setPaginationInfo: (state, action) => {
      state.paginationInfo = action.payload;
    },
    clearStateServers: (state) => {
      state.servers = [];
      state.loading = true;
    },
    removeCreateServerErrors: (state, action) => {
      if (state.createServer.errors) {
        const fieldName = action.payload;
        // eslint-disable-next-line no-unused-vars
        const { [fieldName]: _, ...restErrors } = state.createServer.errors;

        state.createServer.errors = restErrors;
      }
    },
  },
  extraReducers: (builder) => {
    fetchServersReducer(builder);
    updateServerLimitReducer(builder);
    createServerReducer(builder);
  },
});

export const { setPaginationInfo, clearStateServers, removeCreateServerErrors } =
  serversSlice.actions;

export default serversSlice.reducer;
