import { createSlice } from "@reduxjs/toolkit";

import {
  createExchangerUserVerificationReducer,
  fetchExchangerUserVerificationReducer,
  fetchExchangerUserVerifiedVerificationSchemasReducer,
} from "./documentsReducer";

const initialState = {
  link: null,
  status: null,
  schema: null,
  verificationSchemas: null,
  errors: null,
  loading: false,
};

const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    clearStateDocuments: (state) => {
      state.link = null;
      state.status = null;
      state.schema = null;
      state.verificationSchemas = null;
      state.errors = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    fetchExchangerUserVerificationReducer(builder);
    createExchangerUserVerificationReducer(builder);
    fetchExchangerUserVerifiedVerificationSchemasReducer(builder);
  },
});

export const { clearStateDocuments } = documentsSlice.actions;

export default documentsSlice.reducer;
