import axios from "axios";

const axiosInstance = axios.create();

const setupAxiosInterceptors = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const siteUrl = window.location.origin;

  axiosInstance.interceptors.request.use(
    (config) => {
      if (
        baseURL &&
        baseURL !== siteUrl &&
        config.url.startsWith("/api/") &&
        !config.url.includes("/api/exchanger") &&
        !config.url.startsWith("/api/main-exchanger-balance")
      ) {
        config.url = config.url.replace("/api/", "/api/exchanger-");
      }

      return config;
    },
    (error) => Promise.reject(error)
  );
};

setupAxiosInterceptors();

export default axiosInstance;
