import { createSlice } from "@reduxjs/toolkit";

import {
  changePairPercentReducer,
  changeReferralWidgetStatusReducer,
  changeWidgetSettingsReducer,
  createReferralWidgetReducer,
  fetchReferralWidgetByIdReducer,
  fetchReferralWidgetsReducer,
  fetchWidgetPairsReducer,
} from "./referralWidgetReducer";

const initialState = {
  widgets: [],
  widget: {},
  errors: null,
  loading: true,
  createWidgetLoading: false,
  pairs: {
    data: [],
    loading: false,
    errors: null,
    changeParamsLoading: false,
  },
  changeWidgetSettingsLoading: false,
  changeWidgetStatusLoading: false,
  paginationInfo: {
    totalItems: null,
    totalPageCount: null,
    itemsPerPage: null,
  },
};

const referralWidgetSlice = createSlice({
  name: "referralWidget",
  initialState,
  reducers: {
    clearStateWidgets: (state) => {
      state.widgets = [];
      state.widget = {};
      state.loading = true;
      state.errors = null;
      state.pairs = {};
    },
    clearWidgetPairsState: (state) => {
      state.pairs = {};
    },
    setPaginationInfo: (state, action) => {
      state.paginationInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    fetchReferralWidgetsReducer(builder);
    createReferralWidgetReducer(builder);
    fetchReferralWidgetByIdReducer(builder);
    fetchWidgetPairsReducer(builder);
    changePairPercentReducer(builder);
    changeReferralWidgetStatusReducer(builder);
    changeWidgetSettingsReducer(builder);
  },
});

export const { clearStateWidgets, setPaginationInfo, clearWidgetPairsState } =
  referralWidgetSlice.actions;

export default referralWidgetSlice.reducer;
