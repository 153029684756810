const navigationRoutes = [
  {
    path: "/about-us",
    title: "aboutUs",
  },
  {
    path: "/contacts",
    title: "contacts",
  },
];

export default navigationRoutes;
