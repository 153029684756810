import { crmSidebar, transactionDetails, transactions } from "./rbac-consts";

const rules = {
  ROLE_MERCHANT: {
    static: [crmSidebar.TRANSACTION, transactions.MERCHANT, transactionDetails.MERCHANT],
    dynamic: {},
  },
  ROLE_ADMIN: {
    static: [],
    dynamic: {},
  },
};

export default rules;
