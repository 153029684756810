import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const fetchServers = createAsyncThunk(
  "serversSlice/fetchRates",
  async ({ filterUrl }, thunkAPI) => {
    try {
      const url = filterUrl ? `/api/servers${filterUrl}` : "/api/servers";
      const response = await axiosInstance(url, userAuthenticationConfig());

      return {
        data: response.data["hydra:member"],
        paginationInfo: {
          totalPageCount: response.data["hydra:totalPageCount"],
          totalItems: response.data["hydra:totalItems"],
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

const updateServerLimit = createAsyncThunk(
  "serversSlice/updateServerLimit",
  async ({ id, limit }, thunkAPI) => {
    try {
      const response = await axiosInstance.put(
        `/api/servers/${id}`,
        { limit: Number(limit) },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const createServer = createAsyncThunk(
  "exchangersSlice/createServer",
  async ({ data }, thunkAPI) => {
    try {
      const response = await axiosInstance.post("/api/servers", data, userAuthenticationConfig());

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

export { createServer, fetchServers, updateServerLimit };
