import { getItemsPerPage } from "../../../utils/customFunc/getItemsPerPage";
import { fetchFilterData } from "../../../utils/fetchFilterData";

const transformFilterString = (str) => {
  const replacements = {
    "paymentSystem.name": "paymentSystem",
    "service.title": "service",
    "paymentPairUnit.currency": "currency",
  };

  return str.replace(
    /paymentSystem\.name|service\.title|paymentPairUnit\.currency/g,
    (match) => replacements[match]
  );
};

const getFiltersUrlPerRole = (filterData, paginationInfo, isAdminFilters) => {
  const {
    currency,
    "constant[gte]": constantFrom,
    "constant[lte]": constantTo,
    "percent[gte]": percentFrom,
    "percent[lte]": percentTo,
    "minimum[gte]": minimumFrom,
    "minimum[lte]": minimumTo,
    "maximum[gte]": maximumFrom,
    "maximum[lte]": maximumTo,
    ...restFilters
  } = filterData || {};

  const mainFilters = { "currency.asset": currency };

  const exchangerFilters = {
    "paymentPairUnit.currency": currency,
    "constant[gte]": constantFrom,
    "constant[lte]": constantTo,
    "percent[gte]": percentFrom,
    "percent[lte]": percentTo,
    "minimum[gte]": minimumFrom,
    "minimum[lte]": minimumTo,
    "maximum[gte]": maximumFrom,
    "maximum[lte]": maximumTo,
  };

  const filterUrl = fetchFilterData({
    ...restFilters,
    ...(isAdminFilters ? mainFilters : exchangerFilters),
    ...getItemsPerPage(paginationInfo),
  });

  return filterUrl;
};

export { getFiltersUrlPerRole, transformFilterString };
