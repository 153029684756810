import { closableNotification } from "../../../components/elements/notification/ClosableNotification";
import { errorsParser } from "../../../utils/errorsParser";
import {
  changeAvailableServices,
  changeExchangerPairUnit,
  changeExchangerPairUnitActivity,
  changeExchangerPairUnitInputData,
  changeExchangerPairUnitTab,
  fetchAvailableServices,
  fetchExchangerPairUnits,
  fetchExchangerPairUnitsTab,
} from "./asyncPairUnitSlice";

const fetchExchangerPairUnitsReducer = (builder) => {
  builder.addCase(fetchExchangerPairUnits.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchExchangerPairUnits.fulfilled, (state, action) => {
    state.pairUnits = Object.values(action.payload.data);
    state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
    state.loading = false;
  });
  builder.addCase(fetchExchangerPairUnits.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const changeExchangerPairUnitActivityReducer = (builder) => {
  builder.addCase(changeExchangerPairUnitActivity.pending, (state) => {
    state.changeActivityState.loading = true;
  });
  builder.addCase(changeExchangerPairUnitActivity.fulfilled, (state, action) => {
    const newObjectPairUnit = action.payload.data.data[0];

    state.pairUnits = state.pairUnits.map((item) => {
      if (item.id === newObjectPairUnit.id) {
        return {
          ...item,
          exchangerPairUnitId: newObjectPairUnit.exchangerPairUnitId,
          isActive: newObjectPairUnit.isActive,
          fee: newObjectPairUnit.fee,
        };
      }

      return item;
    });

    closableNotification("Payment system activity has been changed", "success");
    state.errors = null;
    state.changeActivityState.loading = false;
  });
  builder.addCase(changeExchangerPairUnitActivity.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeActivityState.loading = false;
  });
};

const fetchExchangerPairUnitsTabsReducer = (builder) => {
  builder.addCase(fetchExchangerPairUnitsTab.pending, (state) => {
    state.pairUnitsTab.loading = true;
  });
  builder.addCase(fetchExchangerPairUnitsTab.fulfilled, (state, action) => {
    state.pairUnitsTab.list = action.payload.data;
    state.pairUnitsTab.loading = false;
  });
  builder.addCase(fetchExchangerPairUnitsTab.rejected, (state, action) => {
    errorsParser(action.payload);
    state.pairUnitsTab.loading = false;
  });
};

const changeExchangerPairUnitReducer = (builder) => {
  builder.addCase(changeExchangerPairUnit.pending, (state) => {
    state.changeDataState.loading = true;
  });
  builder.addCase(changeExchangerPairUnit.fulfilled, (state) => {
    closableNotification("Value successfully updated", "success");
    state.errors = null;
    state.changeDataState.loading = false;
  });
  builder.addCase(changeExchangerPairUnit.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeDataState.loading = false;
  });
};

const changeExchangerPairUnitInputDataReducer = (builder) => {
  builder.addCase(changeExchangerPairUnitInputData.pending, (state) => {
    state.changeDataState.loading = true;
  });
  builder.addCase(changeExchangerPairUnitInputData.fulfilled, (state, action) => {
    state.availableServicesList = action.payload;
    state.changeDataState.loading = false;
    closableNotification("Value successfully updated", "success");
  });
  builder.addCase(changeExchangerPairUnitInputData.rejected, (state, action) => {
    state.changeDataState.loading = false;
    errorsParser(action.payload);
  });
};

const fetchAvailableServicesReducer = (builder) => {
  builder.addCase(fetchAvailableServices.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchAvailableServices.fulfilled, (state, action) => {
    state.availableServices = action.payload.data;
  });
  builder.addCase(fetchAvailableServices.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const changeAvailableServicesReducer = (builder) => {
  builder.addCase(changeAvailableServices.pending, (state) => {
    state.changeAvailableServices.loading = true;
  });
  builder.addCase(changeAvailableServices.fulfilled, (state) => {
    closableNotification("Payment system tab has been changed", "success");
    state.errors = null;
    state.changeAvailableServices.loading = false;
  });
  builder.addCase(changeAvailableServices.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeAvailableServices.loading = false;
  });
};

export {
  changeAvailableServicesReducer,
  changeExchangerPairUnitActivityReducer,
  changeExchangerPairUnitInputDataReducer,
  changeExchangerPairUnitReducer,
  fetchAvailableServicesReducer,
  fetchExchangerPairUnitsReducer,
  fetchExchangerPairUnitsTabsReducer,
};
