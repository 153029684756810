import i18n from "i18next";
import { NavLink } from "react-router-dom";

import { defaultLanguage } from "../../../utils/consts";

const CustomLink = ({ children, to, onClick, className = "", title = null, target = null }) => {
  const language = i18n.language === defaultLanguage ? "" : "/" + i18n.language;

  return (
    <NavLink
      to={`${language}${to}`}
      title={title}
      target={target}
      className={({ isActive }) => `${className} ${isActive ? "active" : ""}`}
      onClick={onClick}
    >
      {children}
    </NavLink>
  );
};

export default CustomLink;
