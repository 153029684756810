import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const fetchOrders = createAsyncThunk("ordersSlice/fetchOrders", async (filterUrl, thunkAPI) => {
  try {
    const response = await axiosInstance(`/api/orders${filterUrl}`, userAuthenticationConfig());

    return {
      data: response.data["hydra:member"],
      paginationInfo: {
        totalPageCount: response.data["hydra:totalPageCount"],
        totalItems: response.data["hydra:totalItems"],
      },
    };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data);
  }
});

export { fetchOrders };
