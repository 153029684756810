import { defaultRoute } from "../../../utils/consts";
import CustomLink from "../../elements/customLink/CustomLink";
import Logo from "../../elements/logo/Logo";
import Navigation from "../../elements/navigation/Navigation";
import {
  StyledHeaderContainer,
  StyledHeaderWrapper,
  StyledMobileSideBarIcon,
} from "./styledHeader";

const Header = ({
  authenticated,
  isAccountPathname,
  collapsed,
  toggled,
  handleCloseSideBar,
  toggleMobile,
}) => {
  const userAccountLink = () => {
    // if (authenticated && user?.roles[arrayOperation.FIRST] === roles.ADMIN) {
    //   return `${typeAccountRoutes.CRM}/clients`;
    // }
    //
    // if (authenticated && user?.roles[arrayOperation.FIRST] === roles.SYSTEM_ADMIN) {
    //   return `${typeAccountRoutes.CRM}/servers`;
    // }

    if (authenticated) {
      return defaultRoute;
    }

    return "/login";
  };

  return (
    <StyledHeaderWrapper>
      <StyledHeaderContainer>
        <div className="header-left">
          <Logo isAccountPathname={isAccountPathname} />
          {isAccountPathname && (
            <button className="header-left__collapse" onClick={handleCloseSideBar}>
              <span className={collapsed ? "icon-chevron-right" : "icon-chevron-left"} />
            </button>
          )}
        </div>
        {!isAccountPathname && (
          <Navigation authenticated={authenticated} userAccountLink={userAccountLink} />
        )}
        <div className="header-right">
          {isAccountPathname ? (
            <StyledMobileSideBarIcon
              authenticated={authenticated}
              show={toggled}
              onClick={toggleMobile}
            >
              <div className="navbar-top" />
              <div className="navbar-middle" />
              <div className="navbar-bottom" />
            </StyledMobileSideBarIcon>
          ) : (
            <CustomLink className="header-right__account" to={userAccountLink()}>
              <span className="header-right__account-title">
                {authenticated ? "My account" : "Sign in"}
              </span>
              <span className="icon-login" />
            </CustomLink>
          )}
        </div>
      </StyledHeaderContainer>
    </StyledHeaderWrapper>
  );
};

export default Header;
