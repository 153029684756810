import { useSelector } from "react-redux";

import { useMedia } from "../../../utils/customHooks/useMedia";
import { StyledSpan } from "../../styles/styledTypography";
import { StyledLogoIcon, StyledLogoWrapper } from "./styledLogo";

const Logo = ({ isAccountPathname }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const siteUrl = window.location.origin;

  const path = baseURL && baseURL !== siteUrl ? "/" : process.env.REACT_APP_LANDING_DOMAIN;

  const { siteConfig } = useSelector((store) => store.theme);

  const isSiteName = siteConfig?.siteName.length > 0;

  const isMobile = useMedia("(max-width: 768px)");

  const mobileLogo = siteConfig?.mobileLogo ?? siteConfig?.logo;

  const logoAlign = isMobile ? { margin: "0 auto" } : { margin: 0 };

  if (isAccountPathname) {
    return (
      <a href={path}>
        <StyledLogoWrapper>
          {siteConfig?.logo && (
            <StyledLogoIcon>
              <img
                src={isMobile ? mobileLogo : siteConfig?.logo}
                alt={`${siteConfig?.siteName}-logo`}
              />
            </StyledLogoIcon>
          )}
          {isSiteName && (
            <StyledSpan fontSize="20" fontWeight="700">
              {siteConfig?.siteName}
            </StyledSpan>
          )}
        </StyledLogoWrapper>
      </a>
    );
  }

  return (
    <a href={path}>
      <StyledLogoWrapper>
        {siteConfig?.logo && (
          <StyledLogoIcon>
            <img
              src={isMobile ? mobileLogo : siteConfig?.logo}
              alt={`${siteConfig?.siteName}-logo`}
            />
          </StyledLogoIcon>
        )}
        {isSiteName && (
          <StyledSpan fontSize="20" fontWeight="700">
            {siteConfig?.siteName}
          </StyledSpan>
        )}
      </StyledLogoWrapper>
    </a>
  );
};

export default Logo;
