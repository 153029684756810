import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const fetchBaseCurrencyRates = createAsyncThunk(
  "ratesSlice/fetchBaseCurrencyRates",
  async ({ filterUrl }, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/base-currency-rate-services${filterUrl}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data["hydra:member"],
        paginationInfo: {
          totalPageCount: response.data["hydra:totalPageCount"],
          totalItems: response.data["hydra:totalItems"],
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchMainExchangerRates = createAsyncThunk(
  "ratesSlice/fetchMainExchangerRates",
  async ({ filterUrl }, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/exchanger-rates${filterUrl}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data["hydra:member"],
        paginationInfo: {
          totalPageCount: response.data["hydra:totalPageCount"],
          totalItems: response.data["hydra:totalItems"],
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data);
    }
  }
);

const fetchExchangerRates = createAsyncThunk(
  "ratesSlice/fetchExchangerRates",
  async ({ filterUrl }, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/exchanger/rates${filterUrl}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data?.exchangerRates,
        paginationInfo: {
          totalPageCount: response.data.totalPageCount,
          totalItems: response.data.totalItems,
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data);
    }
  }
);

const fetchMerchantExchangerRates = createAsyncThunk(
  "ratesSlice/fetchMerchantExchangerRates",
  async ({ filterUrl }, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/exchanger-rates-list${filterUrl}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data?.exchangerRates,
        paginationInfo: {
          totalPageCount: response.data.totalPageCount,
          totalItems: response.data.totalItems,
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data);
    }
  }
);

const changeExchangerMarkup = createAsyncThunk(
  "rateSlice/changeExchangerMarkup",
  async ({ id, data, name }, thunkAPI) => {
    try {
      const response = await axiosInstance.put(
        `/api/exchanger/change-rates-markup/${id}`,
        data,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
        name,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data);
    }
  }
);

const changeMerchantExchangerMarkup = createAsyncThunk(
  "rateSlice/changeMerchantExchangerMarkup",
  async ({ id, data, name }, thunkAPI) => {
    try {
      const response = await axiosInstance.patch(
        `/api/exchanger-rates/${id}/exchanger`,
        data,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
        name,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data);
    }
  }
);

const changeOurMarkup = createAsyncThunk(
  "rateSlice/changeOurMarkup",
  async ({ id, data, name }, thunkAPI) => {
    try {
      const response = await axiosInstance.patch(
        `/api/exchanger-rates/${id}`,
        data,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
        name,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data);
    }
  }
);

export {
  changeExchangerMarkup,
  changeMerchantExchangerMarkup,
  changeOurMarkup,
  fetchBaseCurrencyRates,
  fetchExchangerRates,
  fetchMainExchangerRates,
  fetchMerchantExchangerRates,
};
