import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { responseStatus } from "../../utils/consts";
import { errorsParser } from "../../utils/errorsParser";
import { parseAxiosError } from "../../utils/response";
import {
  handleChangePassword,
  handleCheckTwoFaIsActive,
  handleForgotPassword,
  handleGetAuthenticatorSecretUser,
  handleRecoverPassword,
  handleRemoveAuthenticatorSecretUser,
  handleSetAuthenticatorSecretUser,
} from "./asyncUserSlice";
import { setUserErrors } from "./userSlice";

const handleForgotPasswordReducer = (builder) => {
  builder.addCase(handleForgotPassword.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(handleForgotPassword.fulfilled, (state, action) => {
    closableNotification(action.payload.data.success, "success");
    state.loading = false;
  });
  builder.addCase(handleForgotPassword.rejected, (state, action) => {
    closableNotification(action.payload.errors[0], "error");
    state.loading = false;
  });
};

const handleTwoFaIsActiveReducer = (builder) => {
  builder.addCase(handleCheckTwoFaIsActive.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(handleCheckTwoFaIsActive.fulfilled, (state, action) => {
    state.twoFaIsActive = action.payload.data.isActive;
    state.loading = false;
  });
  builder.addCase(handleCheckTwoFaIsActive.rejected, (state, action) => {
    state.loading = false;
    errorsParser(action.payload);
  });
};

const getUserSecretReducer = (builder) => {
  builder.addCase(handleGetAuthenticatorSecretUser.pending, () => {});
  builder.addCase(handleGetAuthenticatorSecretUser.fulfilled, (state, action) => {
    state.authenticatorSecret = action.payload.data;
  });
  builder.addCase(handleGetAuthenticatorSecretUser.rejected, (state, action) => {
    errorsParser(action.payload);
  });
};

const setUserSecretReducer = (builder) => {
  builder.addCase(handleSetAuthenticatorSecretUser.pending, () => {});
  builder.addCase(handleSetAuthenticatorSecretUser.fulfilled, (state, action) => {
    state.twoFaIsActive = true;
    action.payload.callback();
  });
  builder.addCase(handleSetAuthenticatorSecretUser.rejected, (state, action) => {
    errorsParser(action.payload);
  });
};

const removeUserSecretReducer = (builder) => {
  builder.addCase(handleRemoveAuthenticatorSecretUser.pending, () => {});
  builder.addCase(handleRemoveAuthenticatorSecretUser.fulfilled, (state, action) => {
    state.twoFaIsActive = false;
    action.payload.callback();
  });
  builder.addCase(handleRemoveAuthenticatorSecretUser.rejected, (state, action) => {
    errorsParser(action.payload);
  });
};

const recoverUserPasswordReducer = (builder) => {
  /*RECOVER PASSWORD AFTER REDIRECT FROM EMAIL LINK*/
  builder.addCase(handleRecoverPassword.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(handleRecoverPassword.fulfilled, (state, action) => {
    state.loading = false;
    closableNotification(action.payload?.data?.success, "success");
    action.payload.callback();
  });
  builder.addCase(handleRecoverPassword.rejected, (state, action) => {
    state.loading = false;
    const errorMessage = Object.entries(action.payload?.data?.data?.errors)[0];
    closableNotification(errorMessage[1], "error");
  });
};

const changeUserPasswordReducer = (builder) => {
  builder.addCase(handleChangePassword.pending, () => {});
  builder.addCase(handleChangePassword.fulfilled, (state, action) => {
    setUserErrors(state, null);

    action.payload.callback();

    closableNotification(action.payload.data.success, "success");
  });
  builder.addCase(handleChangePassword.rejected, (state, action) => {
    state.errors = parseAxiosError(action.payload);
    const errorMessage = Object.entries(action.payload?.data?.data?.errors)[0];
    closableNotification(errorMessage[1], "error");

    if (action.payload.status === responseStatus.HTTP_BAD_REQUEST) {
      const key = Object.keys(action.payload.data);
      state.errors[key] = Object.values(action.payload.data);
    }
  });
};

export {
  changeUserPasswordReducer,
  getUserSecretReducer,
  handleForgotPasswordReducer,
  handleTwoFaIsActiveReducer,
  recoverUserPasswordReducer,
  removeUserSecretReducer,
  setUserSecretReducer,
};
