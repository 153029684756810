import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { errorsParser } from "../../utils/errorsParser";
import { createServer, fetchServers, updateServerLimit } from "./asyncServersSlice";

const fetchServersReducer = (builder) => {
  builder.addCase(fetchServers.fulfilled, (state, action) => {
    state.servers = action.payload.data;
    state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
    state.loading = false;
  });

  builder.addCase(fetchServers.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(fetchServers.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const updateServerLimitReducer = (builder) => {
  builder.addCase(updateServerLimit.fulfilled, (state, action) => {
    const newObjectExchanger = action.payload.data;

    state.servers = state.servers.map((item) => {
      if (item.id === newObjectExchanger.id) {
        return { ...item, ...newObjectExchanger };
      }
      return item;
    });

    state.errors = null;
    closableNotification("Limit was successfully updated", "success");
  });

  builder.addCase(updateServerLimit.rejected, (state, action) => {
    errorsParser(action.payload);
  });
};

const createServerReducer = (builder) => {
  builder.addCase(createServer.pending, (state) => {
    state.createServer.loading = true;
  });
  builder.addCase(createServer.fulfilled, (state, action) => {
    state.createServer.errors = null;
    state.createServer.loading = false;
    state.servers = [action.payload.data, ...state.servers];
    closableNotification("New server has been created", "success");
  });
  builder.addCase(createServer.rejected, (state, action) => {
    state.createServer.errors = errorsParser(action.payload, true);
    state.createServer.loading = false;
  });
};

export { createServerReducer, fetchServersReducer, updateServerLimitReducer };
