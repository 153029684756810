import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import Layout from "../../components/layout/Layout";
import {
  defaultLanguage,
  defaultRoute,
  defaultSystemAdminRoute,
  mainRoles,
  typeAccountRoutes,
} from "../../utils/consts";
import { checkPrivateRouters } from "../../utils/customFunc/checkPrivateRouters";
import getUserInfo from "../../utils/getUserInfo";
import SuspenseWrapper from "./SuspenseWrapper";

const RouterBlock = ({ isAccountPathname }) => {
  const [cookies] = useCookies(["i18next"]);

  const { authenticated } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const systemAdminRole = getUserInfo()?.roles?.includes(mainRoles.SYSTEM_ADMIN);

  const { routers } = checkPrivateRouters(authenticated);

  return (
    <Routes>
      {!authenticated ? (
        <Route
          path="/"
          element={
            <Navigate
              to={`${cookies.i18next && cookies.i18next !== defaultLanguage ? `/${cookies.i18next}/` : "/"}login`}
              replace={true}
            />
          }
        />
      ) : (
        <Route
          path="/"
          element={
            <Navigate
              to={`${cookies.i18next && cookies.i18next !== defaultLanguage ? `/${cookies.i18next}/` : ""}${!systemAdminRole ? defaultRoute : defaultSystemAdminRoute}`}
              replace={true}
            />
          }
        />
      )}
      <Route
        path="/"
        element={
          <Layout
            routers={routers}
            authenticated={authenticated}
            isAccountPathname={isAccountPathname}
          />
        }
      >
        {routers.map((route, index) => {
          return (
            <Route
              key={index}
              path={`/:locale?${route.path}`}
              element={
                <SuspenseWrapper
                  pageComponentPath={route.pathComponentPage}
                  permission={route.permission}
                  isSuspense={route.isSuspense}
                  cookiesLang={cookies.i18next}
                  checkFullAccess={route?.checkFullAccess}
                  dispatch={dispatch}
                />
              }
            />
          );
        })}
      </Route>
      <Route
        path="*"
        element={
          !authenticated ? (
            <Navigate
              to={`${cookies.i18next && cookies.i18next !== defaultLanguage ? `/${cookies.i18next}/` : "/"}login`}
              replace={true}
            />
          ) : (
            <Navigate
              to={`${cookies.i18next && cookies.i18next !== defaultLanguage ? `/${cookies.i18next}` : ""}${typeAccountRoutes.CRM}/page-not-found`}
              replace={true}
            />
          )
        }
      />
    </Routes>
  );
};

export default RouterBlock;
