import Skeleton from "react-loading-skeleton";

import { StyledContainer } from "../../styles/styledContainer";
import { StyledFilterHead, StyledFilterWrapperOld } from "../../styles/styledFilter";
import SkeletonMainBlock from "../SkeletonMainBlock";

const GoodsPageSkeleton = () => {
  return (
    <StyledContainer>
      <SkeletonMainBlock>
        <div>
          <Skeleton height={40} width={68} count={1} containerClassName="base-skeleton-wrapper" />
          <StyledFilterWrapperOld id="filter" pb="25">
            <StyledFilterHead>
              <Skeleton height={40} width={68} count={1} />
              <Skeleton height={40} width={68} count={1} />
            </StyledFilterHead>
          </StyledFilterWrapperOld>
          <div>
            <Skeleton height={40} count={1} containerClassName="base-skeleton-wrapper" />
            <Skeleton height={40} count={1} containerClassName="base-skeleton-wrapper" />

            <Skeleton height={40} width={68} count={1} containerClassName="base-skeleton-wrapper" />
          </div>
          <Skeleton height={50} count={10} containerClassName="base-skeleton-wrapper" />
        </div>
      </SkeletonMainBlock>
    </StyledContainer>
  );
};

export default GoodsPageSkeleton;
