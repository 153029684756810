import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { fetchPaymentSystem } from "./asyncPaymentSystemSlice";

const fetchPaymentSystemReducers = (builder) => {
  builder.addCase(fetchPaymentSystem.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchPaymentSystem.fulfilled, (state, action) => {
    state.paymentSystem = action.payload.data;
    state.loading = false;
  });
  builder.addCase(fetchPaymentSystem.rejected, (state, action) => {
    closableNotification(action.payload, "error");
    state.loading = false;
  });
};

export { fetchPaymentSystemReducers };
