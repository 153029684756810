import styled from "styled-components";

import { StyledHeadingOne } from "../../styles/styledTypography";
import CustomLink from "../customLink/CustomLink";

export const StyledPageHeadWrapper = styled.div`
  ${({ pb }) => (pb ? `padding-bottom: ${pb}px;` : "padding-bottom: 40px;")};

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  @media screen and (max-width: 992px) {
    padding-bottom: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
`;

export const StyledPageTitleContainer = styled.div`
  width: ${({ actionsInside }) => (actionsInside !== "false" ? "fit-content" : "100%")};
  display: flex;
  align-items: flex-end;
  overflow: hidden;
`;

export const StyledPageTitle = styled(StyledHeadingOne)`
  max-width: 900px;
  text-overflow: ellipsis;
  position: relative;

  @media screen and (max-width: 992px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const StyledChildrenContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const StyledPagePrevious = styled(CustomLink)`
  padding-right: 21px;
  padding-bottom: 5px;
  color: ${({ theme }) => theme.neutral.third};
  font-size: 14px;
  white-space: nowrap;
  position: relative;
  transition: all 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.primary.first};
  }

  &::after {
    content: "/";
    color: ${({ theme }) => theme.neutral.third};
    position: absolute;
    left: calc(100% - 14px);
    bottom: 5px;
  }

  @media screen and (max-width: 576px) {
    display: none;
  }
`;

export const StyledMobilePagePrevious = styled(StyledPagePrevious)`
  display: none;
  @media screen and (max-width: 576px) {
    display: flex;
    align-items: center;
    span {
      margin-right: 10px;
      font-size: 24px;
    }

    &::after {
      content: unset;
    }
  }
`;
