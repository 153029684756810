import { closableNotification } from "../../../components/elements/notification/ClosableNotification";
import { fetchTransactionDetails } from "./asyncTransactionDetailsSlice";

const fetchTransactionDetailsReducer = (builder) => {
  builder.addCase(fetchTransactionDetails.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchTransactionDetails.fulfilled, (state, action) => {
    state.transactionDetails = action.payload;
    state.loading = false;
  });
  builder.addCase(fetchTransactionDetails.rejected, (state, action) => {
    closableNotification(action.payload, "error");
    state.loading = false;
  });
};

export { fetchTransactionDetailsReducer };
