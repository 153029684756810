import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const fetchExchangers = createAsyncThunk(
  "exchangersSlice/fetchExchangers",
  async (filterUrl, thunkAPI) => {
    const url = filterUrl ? `/api/exchangers${filterUrl}` : "/api/exchangers";

    try {
      const response = await axiosInstance(url, userAuthenticationConfig());

      return {
        data: response.data["hydra:member"],
        paginationInfo: {
          totalPageCount: response.data["hydra:totalPageCount"],
          totalItems: response.data["hydra:totalItems"],
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const createExchanger = createAsyncThunk(
  "exchangersSlice/createExchanger",
  async ({ data }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/exchangers",
        data,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

export { createExchanger, fetchExchangers };
