import { createSlice } from "@reduxjs/toolkit";

import { fetchTransactionDetailsReducer } from "./transactionDetailsReducers";

const initialState = {
  loading: true,
  transactionDetails: null,
};

const transactionDetailsSlice = createSlice({
  name: "transactionDetailsSlice",
  initialState,
  reducers: {
    clearTransactionDetailsStore: (state) => {
      state.loading = true;
      state.transactionDetails = null;
    },
  },
  extraReducers: (builder) => {
    fetchTransactionDetailsReducer(builder);
  },
});

export const { clearTransactionDetailsStore } = transactionDetailsSlice.actions;

export default transactionDetailsSlice.reducer;
