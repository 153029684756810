import styled from "styled-components";

export const StyledSelect = styled.div`
  ${({ width }) =>
    width &&
    `
    max-width: ${width}px;
    width: 100%;
  `};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  cursor: pointer;

  .custom-select,
  .custom-multiselect {
    z-index: 0;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.neutral.five};
    border-radius: 12px;
    background-color: ${({ theme }) => theme.neutral.white};
    overflow: hidden;
    transition: border 0.1s ease;

    .rc-select-selection-placeholder {
      color: ${({ theme }) => theme.neutral.third};
    }

    .option-select-item_with-img {
      [class^="icon-"],
      [class*="icon-"] {
        color: ${({ theme }) => theme.primary.first};
        padding-right: 16px;
        font-size: 16px;
      }

      img {
        height: 22px;
        width: 22px;
        margin-right: 20px;
        object-fit: contain;
        object-position: center;
      }
    }

    ${({ theme, error }) => error && `border-color: ${theme.danger.first}`}
  }

  .rc-select-focused {
    border: 1px solid ${({ theme }) => theme.primary.first};
  }

  .custom-multiselect {
    .rc-select-selection-item {
      margin: 5px;
      padding: 5px;
      display: inline-flex;
      background-color: ${({ theme }) => theme.primary.second};
      border: none;
      border-radius: 5px;
      color: ${({ theme }) => theme.neutral.first};

      .rc-select-selection-item-content {
        width: 100%;
      }
    }

    .option-select-item {
      width: 100%;
    }
  }

  .custom-select {
    .option-select-item {
      width: calc(100% - 50px);
      color: ${({ theme }) => theme.neutral.first};
      display: flex;
      align-items: center;
      text-transform: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .rc-select-arrow {
    height: 100%;
    z-index: -1;

    .rc-select-arrow-icon {
      border: none;
      position: relative;
      top: 8px;
      left: -10px;
      margin: 0;

      &:before {
        content: "\\e90a";
        height: 20px;
        width: 20px;
        color: ${({ theme }) => theme.primary.first};
        font-size: 20px;
        font-family: "default-icons", serif;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .rc-select-selection-item-remove-icon {
    margin-left: 5px;
    color: ${({ theme }) => theme.primary.first};

    &:hover {
      opacity: 1;
    }
  }

  .rc-select-selection-search {
    width: 100%;
    height: 38px;
    position: absolute;
    top: 0;
    left: 0;

    .rc-select-selection-search-input {
      width: 100%;
      height: 100%;
      padding: 0 16px;
      font-size: 14px;
      background-color: transparent;
      border: none;
    }
  }

  .rc-select-open .rc-select-arrow .rc-select-arrow-icon:before {
    content: "\\e90d";
  }
`;

export const StyledSelectLabel = styled.label`
  padding-bottom: 8px;
  color: ${({ theme }) => theme.neutral.first};
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  display: flex;
`;

export const StyledSelectError = styled.div`
  padding-top: 3px;
  color: ${({ theme }) => theme.danger.first};
  font-size: 12px;
  text-align: left;
`;
