import { createSlice } from "@reduxjs/toolkit";

import { fetchReferralTokenReducer, fetchReferralUsersReducer } from "./referralSystemReducer";

const initialState = {
  referralToken: "",
  errors: null,
  loading: true,
  referralUsers: {
    loading: true,
    list: [],
  },
  // paginationInfo: {
  //   totalItems: null,
  //   totalPageCount: null,
  //   itemsPerPage: null,
  // },
};

const referralSystemSlice = createSlice({
  name: "referralSystem",
  initialState,
  reducers: {
    // setPaginationInfo: (state, action) => {
    //   state.paginationInfo = action.payload;
    // },
    clearStateConnections: (state) => {
      state.referralToken = [];
      state.loading = true;
      state.errors = null;
    },
    clearStateReferralUsers: (state) => {
      state.list = [];
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    fetchReferralTokenReducer(builder);
    fetchReferralUsersReducer(builder);
  },
});

export const { clearStateConnections, clearStateReferralUsers } = referralSystemSlice.actions;

export default referralSystemSlice.reducer;
