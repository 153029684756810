import { closableNotification } from "../../../components/elements/notification/ClosableNotification";
import { errorsParser } from "../../../utils/errorsParser";
import {
  createPayment,
  createPayout,
  fetchAvailableCurrencies,
  fetchAvailablePaymentSystems,
  fetchAvailableServices,
  fetchExchangerPairUnitData,
  fetchPaymentTransactionAttributes,
  fetchPayoutTransactionAttributes,
  fetchTransactionsList,
} from "./asyncTransactionsSlice";

const fetchAvailableServicesReducer = (builder) => {
  builder.addCase(fetchAvailableServices.pending, (state) => {
    state.selectLoading = true;
  });
  builder.addCase(fetchAvailableServices.fulfilled, (state, action) => {
    state.availableServicesList = action.payload;
    state.selectLoading = false;
  });
  builder.addCase(fetchAvailableServices.rejected, (state, action) => {
    closableNotification(action.payload, "error");
    state.selectLoading = false;
  });
};

const fetchAvailableCurrenciesReducer = (builder) => {
  builder.addCase(fetchAvailableCurrencies.pending, (state) => {
    state.selectLoading = true;
  });
  builder.addCase(fetchAvailableCurrencies.fulfilled, (state, action) => {
    state.availableCurrenciesList = action.payload;
    state.selectLoading = false;
  });
  builder.addCase(fetchAvailableCurrencies.rejected, (state, action) => {
    closableNotification(action.payload, "error");
    state.selectLoading = false;
  });
};

const fetchAvailablePaymentSystemsReducer = (builder) => {
  builder.addCase(fetchAvailablePaymentSystems.pending, (state) => {
    state.selectLoading = true;
  });
  builder.addCase(fetchAvailablePaymentSystems.fulfilled, (state, action) => {
    state.availablePaymentSystemList = action.payload;
    state.selectLoading = false;
  });
  builder.addCase(fetchAvailablePaymentSystems.rejected, (state, action) => {
    closableNotification(action.payload, "error");
    state.selectLoading = false;
  });
};

const fetchTransactionsListReducer = (builder) => {
  builder.addCase(fetchTransactionsList.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchTransactionsList.fulfilled, (state, action) => {
    state.loading = false;

    if (action.payload.type === "payment") {
      state.paymentList = action.payload.data;
    } else if (action.payload.type === "payout") {
      state.payoutList = action.payload.data;
    } else {
      state.allTransactionsList = action.payload.data;
    }
  });
  builder.addCase(fetchTransactionsList.rejected, (state, action) => {
    state.loading = false;
    state.errors = action.payload?.data;
    errorsParser(action.payload?.data);
  });
};

const fetchPaymentTransactionAttributesReducer = (builder) => {
  builder.addCase(fetchPaymentTransactionAttributes.pending, (state) => {
    state.createLoading = true;
  });
  builder.addCase(fetchPaymentTransactionAttributes.fulfilled, (state, action) => {
    state.createLoading = false;
    state.paymentAttributes = action.payload.payment;
  });
  builder.addCase(fetchPaymentTransactionAttributes.rejected, (state, action) => {
    errorsParser(action.payload.data);
    state.createLoading = false;
  });
};

const fetchPayoutTransactionAttributesReducer = (builder) => {
  builder.addCase(fetchPayoutTransactionAttributes.pending, (state) => {
    state.createLoading = true;
  });
  builder.addCase(fetchPayoutTransactionAttributes.fulfilled, (state, action) => {
    state.createLoading = false;
    state.payoutAttributes = action.payload.payout;
  });
  builder.addCase(fetchPayoutTransactionAttributes.rejected, (state, action) => {
    errorsParser(action.payload.data);
    state.createLoading = false;
  });
};

const createPaymentReducer = (builder) => {
  builder.addCase(createPayment.pending, (state) => {
    state.createLoading = true;
    state.isPaymentCreated = false;
  });
  builder.addCase(createPayment.fulfilled, (state, action) => {
    state.createLoading = false;
    closableNotification("Payment successfully created", "success");
    state.paymentList.unshift(action.payload);
    state.allTransactionsList.unshift(action.payload);
    state.isTransactionCreated = true;
  });
  builder.addCase(createPayment.rejected, (state, action) => {
    errorsParser(action.payload);
    state.createLoading = false;
  });
};

const createPayoutReducer = (builder) => {
  builder.addCase(createPayout.pending, (state) => {
    state.createLoading = true;
    state.isPayoutCreated = false;
  });
  builder.addCase(createPayout.fulfilled, (state, action) => {
    state.createLoading = false;
    closableNotification("Payout successfully created", "success");
    state.payoutList.unshift(action.payload);
    state.allTransactionsList.unshift(action.payload);
    state.isTransactionCreated = true;
  });
  builder.addCase(createPayout.rejected, (state, action) => {
    errorsParser(action.payload);
    state.createLoading = false;
  });
};

const fetchExchangerPairUnitDataReducer = (builder) => {
  builder.addCase(fetchExchangerPairUnitData.pending, (state) => {
    state.createLoading = true;
  });
  builder.addCase(fetchExchangerPairUnitData.fulfilled, (state, action) => {
    state.pairUnitId = action.payload?.id;
    state.createLoading = false;
  });
  builder.addCase(fetchExchangerPairUnitData.rejected, (state, action) => {
    errorsParser(action.payload.data);
    state.createLoading = false;
  });
};

export {
  createPaymentReducer,
  createPayoutReducer,
  fetchAvailableCurrenciesReducer,
  fetchAvailablePaymentSystemsReducer,
  fetchAvailableServicesReducer,
  fetchExchangerPairUnitDataReducer,
  fetchPaymentTransactionAttributesReducer,
  fetchPayoutTransactionAttributesReducer,
  fetchTransactionsListReducer,
};
