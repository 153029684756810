import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { errorsParser } from "../../utils/errorsParser";
import {
  changeReferralWidgetStatus,
  changeWidgetPair,
  changeWidgetSettings,
  createReferralWidget,
  fetchReferralWidgetById,
  fetchReferralWidgets,
  fetchWidgetPairs,
} from "./asyncReferralWidget";

const fetchReferralWidgetsReducer = (builder) => {
  builder.addCase(fetchReferralWidgets.fulfilled, (state, action) => {
    state.widgets = action.payload.data;
    state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
    state.loading = false;
  });
  builder.addCase(fetchReferralWidgets.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const fetchReferralWidgetByIdReducer = (builder) => {
  builder.addCase(fetchReferralWidgetById.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchReferralWidgetById.fulfilled, (state, action) => {
    state.widget = action.payload.data;
    state.loading = false;
  });
  builder.addCase(fetchReferralWidgetById.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const createReferralWidgetReducer = (builder) => {
  builder.addCase(createReferralWidget.pending, (state) => {
    state.createWidgetLoading = true;
  });
  builder.addCase(createReferralWidget.fulfilled, (state, action) => {
    const newWidget = action.payload.data;
    state.widgets = [...state.widgets, newWidget];

    action.payload.callback();
    state.createWidgetLoading = false;
    closableNotification("Widget was created successfully", "success");
  });
  builder.addCase(createReferralWidget.rejected, (state, action) => {
    errorsParser(action.payload);
    state.createWidgetLoading = false;
  });
};

const changeReferralWidgetStatusReducer = (builder) => {
  builder.addCase(changeReferralWidgetStatus.pending, (state) => {
    state.changeWidgetStatusLoading = true;
  });
  builder.addCase(changeReferralWidgetStatus.fulfilled, (state, action) => {
    state.widget = action.payload.data;
    state.changeWidgetStatusLoading = false;
    closableNotification("Widget status successfully changed", "success");
  });
  builder.addCase(changeReferralWidgetStatus.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeWidgetStatusLoading = false;
  });
};

const fetchWidgetPairsReducer = (builder) => {
  builder.addCase(fetchWidgetPairs.pending, (state) => {
    state.pairs.loading = true;
  });
  builder.addCase(fetchWidgetPairs.fulfilled, (state, action) => {
    state.pairs.data = action.payload.data;
    state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
    state.pairs.loading = false;
  });
  builder.addCase(fetchWidgetPairs.rejected, (state, action) => {
    errorsParser(action.payload);
    state.pairs.loading = false;
  });
};

const changeWidgetSettingsReducer = (builder) => {
  builder.addCase(changeWidgetSettings.pending, (state) => {
    state.changeWidgetSettingsLoading = true;
  });
  builder.addCase(changeWidgetSettings.fulfilled, (state, action) => {
    state.widget = { ...state.widget, ...action.payload?.data?.widgetSettings };
    closableNotification("Widget settings was successfully updated", "success");
    state.changeWidgetSettingsLoading = false;
  });
  builder.addCase(changeWidgetSettings.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeWidgetSettingsLoading = false;
  });
};

const changePairPercentReducer = (builder) => {
  builder.addCase(changeWidgetPair.pending, (state) => {
    state.pairs.changeParamsLoading = true;
  });
  builder.addCase(changeWidgetPair.fulfilled, (state, action) => {
    const newObjectPair = action.payload.data;

    state.pairs.data = state.pairs.data.map((item) => {
      if (item.id === newObjectPair.id) {
        return newObjectPair;
      }
      return item;
    });

    action.payload.notificationsCallback && action.payload.notificationsCallback();
    state.errors = null;
    state.pairs.changeParamsLoading = false;
  });
  builder.addCase(changeWidgetPair.rejected, (state, action) => {
    errorsParser(action.payload);
    state.pairs.changeParamsLoading = false;
  });
};

export {
  changePairPercentReducer,
  changeReferralWidgetStatusReducer,
  changeWidgetSettingsReducer,
  createReferralWidgetReducer,
  fetchReferralWidgetByIdReducer,
  fetchReferralWidgetsReducer,
  fetchWidgetPairsReducer,
};
