import "./i18n";
import "./assets/css/main.min.css";
import "./assets/fonts/default-icons/style.css";

import i18n from "i18next";
import { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import AutoLogoutModal from "./components/elements/modal/AutoLogoutModal";
import { GlobalStyle } from "./components/styles/globalStyle";
import { StyledMainWrapper } from "./components/styles/styledContainer";
import RouterBlock from "./routes/routerComponents/RouterBlock";
import { handleLogout } from "./store/auth/authSlice";
import { fetchThemeConfig } from "./store/theme/asyncThemeSlice";
import { removeUserInfo } from "./store/user/userSlice";
import { defaultLanguage, helmetHtmlByLang, regex } from "./utils/consts";
import useRefToken from "./utils/customHooks/useRefToken";
import eventBus from "./utils/eventBus";
import axiosInstance from "./utils/setupAxiosInterceptors";
import userAuthenticationConfig from "./utils/userAuthenticationConfig";

function App() {
  const url = window.location.href;

  const baseURL = process.env.REACT_APP_BASE_URL;

  const siteUrl = window.location.origin;

  const isAccountPathname = regex.CRM_URL.test(url);

  useRefToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);

  const [lngFromUrl, setLngFromUrl] = useState(defaultLanguage);

  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLngFromUrl(language);
    });

    return () => {
      i18n.off("languageChanged");
    };
  }, []);

  useEffect(() => {
    if (baseURL === siteUrl) {
      return;
    }

    dispatch(fetchThemeConfig());
  }, []);

  const isValidToken = async () => {
    try {
      await axiosInstance.post("/api/is-valid-token", {}, userAuthenticationConfig(true));
    } catch (error) {
      if (!error.response) {
        // eslint-disable-next-line no-console
        console.warn("Temporary network or server error.");
      } else {
        localStorage.removeItem("token");
        dispatch(handleLogout());
        dispatch(removeUserInfo());
        navigate("/login");
      }
    }
  };

  const handleOnIdle = () => {
    eventBus.on("logout", (data) => {
      if (data?.expired) {
        setVisible(true);
      }
      localStorage.removeItem("token");

      dispatch(handleLogout());
      dispatch(removeUserInfo());

      navigate("/login");
    });
  };

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      isValidToken().then();
    }
    handleOnIdle();
  }, []);

  const { themeMode, loading } = useSelector((state) => state.theme);

  const { siteConfig } = useSelector((store) => store.theme);

  if (baseURL !== siteUrl && loading) {
    return <div />;
  }

  return (
    <ThemeProvider theme={themeMode}>
      <HelmetProvider>
        <Helmet>
          {siteConfig.favicon && <link rel="icon preconnect" href={siteConfig.favicon} />}
          <html lang={helmetHtmlByLang[lngFromUrl]} />
        </Helmet>
        <AutoLogoutModal visible={visible} setVisible={setVisible} />
        <StyledMainWrapper>
          <RouterBlock isAccountPathname={isAccountPathname} />
        </StyledMainWrapper>
        <GlobalStyle />
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
