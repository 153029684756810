import styled from "styled-components";

export const StyledLayoutWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-top: 60px;
  flex: 1 1 auto;
  display: flex;
  position: relative;

  @media screen and (max-width: 992px) {
    padding-top: 50px;
  }
`;

export const StyledUserLayoutWrapper = styled.div`
  width: 100%;
  max-width: ${({ changeWidth }) => (changeWidth ? "calc(100% - 104px)" : "calc(100% - 258px)")};
  margin-left: auto;
  flex: 1 1 auto;
  display: flex;
  position: relative;
  overflow-x: hidden;
  transition: width, left, right, 300ms;

  @media screen and (max-width: 992px) {
    max-width: 100%;
  }
`;
