import { errorsParser } from "../../utils/errorsParser";
import { fetchBalances, fetchExchangerBalances } from "./asyncBalancesSlice";

const fetchBalancesReducer = (builder) => {
  builder.addCase(fetchBalances.fulfilled, (state, action) => {
    state.balances = action.payload.data;
    state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
    state.loading = false;
  });

  builder.addCase(fetchBalances.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(fetchBalances.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const fetchExchangerBalancesReducer = (builder) => {
  builder.addCase(fetchExchangerBalances.fulfilled, (state, action) => {
    state.balances = action.payload.data;
    state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
    state.loading = false;
  });

  builder.addCase(fetchExchangerBalances.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(fetchExchangerBalances.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

export { fetchBalancesReducer, fetchExchangerBalancesReducer };
