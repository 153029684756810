export const permissionMainAdminEndpoints = {
  FILL_PAIR_UNITS: { name: "fill_pair_units", hasFullAccess: true },
  FILL_ENDPOINTS: { name: "fill_endpoints", hasFullAccess: true },
  FILL_GROUP_AND_ENDPOINT_PERMISSIONS: {
    name: "fill_group_and_endpoint_permissions",
    hasFullAccess: true,
  },
  FILL_EXCHANGER_ENDPOINT_PERMISSION_FOR_NEW_USER: {
    name: "fill_exchanger_endpoint_permissions_for_new_user",
    hasFullAccess: true,
  },
  FILL_EXCHANGER_PERMISSIONS: { name: "fill_exchanger_permissions", hasFullAccess: true },
  FILL_PAIRS: {
    name: "fill_pairs",
    hasFullAccess: true,
  },
  CHECK_DIFFERENCE_BETWEEN_ENDPOINTS_AND_GROUP_ENDPOINTS: {
    name: "check_difference_between_endpoints_and_group_endpoints",
    hasFullAccess: true,
  },
  PUT_CHANGE_AVATAR: { name: "put_changer_avatar", hasFullAccess: true },
  PUT_REMOVE_AVATAR: { name: "put_remove_avatar", hasFullAccess: true },
  TEST_EMAIL: { name: "test_email", hasFullAccess: true },
  API_BALANCES_GET_COLLECTION: { name: "api_balances_get_collection", hasFullAccess: true },
  API_BALANCES_GET_ITEM: { name: "api_balances_get_item", hasFullAccess: true },
  API_CONNECTIONS_GET_COLLECTION: { name: "api_connections_get_collection", hasFullAccess: true },
  API_CONNECTIONS_GET_ITEM: { name: "api_connections_get_item", hasFullAccess: true },
  API_ENDPOINTS_GET_COLLECTION: { name: "api_endpoints_get_collection", hasFullAccess: true },
  API_ENDPOINTS_GET_ITEM: { name: "api_endpoints_get_item", hasFullAccess: true },
  POST_ENDPOINT_PERMISSION_BY_GROUP: {
    name: "post_endpoint_permission_by_group",
    hasFullAccess: true,
  },
  API_ENDPOINT_PERMISSION_GET_COLLECTION: {
    name: "api_endpoint_permissions_get_collection",
    hasFullAccess: true,
  },
  API_ENDPOINT_PERMISSION_POST_COLLECTION: {
    name: "api_endpoint_permissions_post_collection",
    hasFullAccess: true,
  },
  API_ENDPOINT_PERMISSION_GET_ITEM: {
    name: "api_endpoint_permissions_get_item",
    hasFullAccess: true,
  },
  API_ENDPOINT_PERMISSION_DELETE_ITEM: {
    name: "api_endpoint_permissions_delete_item",
    hasFullAccess: true,
  },
  API_GROUP_PERMISSIONS_GET_COLLECTION: {
    name: "api_group_permissions_get_collection",
    hasFullAccess: true,
  },
  API_GROUP_PERMISSIONS_GET_ITEM: { name: "api_group_permissions_get_item", hasFullAccess: true },
  API_EXCHANGERS_GET_COLLECTION: { name: "api_exchangers_get_collection", hasFullAccess: true },
  API_EXCHANGERS_GET_ITEM: { name: "api_exchangers_get_item", hasFullAccess: true },
  API_EXCHANGERS_CHANGE_STATUS_ITEM: {
    name: "api_exchangers_change_status_item",
    hasFullAccess: true,
  },
  API_EXCHANGERS_CHANGE_CONNECTION_FIELDS_ITEM: {
    name: "api_exchangers_change_connection_fields_item",
    hasFullAccess: true,
  },
  API_EXCHANGER_CONNECTION_GET_COLLECTION: {
    name: "api_exchanger_connections_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_CONNECTION_POST_COLLECTION: {
    name: "api_exchanger_connections_post_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_CONNECTION_GET_ITEM: {
    name: "api_exchanger_connections_get_item",
    hasFullAccess: true,
  },
  API_EXCHANGER_DATABASE_CONNECTIONS_GET_COLLECTION: {
    name: "api_exchanger_database_connections_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_DATABASE_CONNECTIONS_GET_ITEM: {
    name: "api_exchanger_database_connections_get_item",
    hasFullAccess: true,
  },
  API_EXCHANGER_DATABASE_CONNECTIONS_PUT_ITEM: {
    name: "api_exchanger_database_connections_put_item",
    hasFullAccess: true,
  },
  HANDLE_EXCHANGER_SETTINGS: { name: "handle_exchanger_settings", hasFullAccess: true },
  API_EXCHANGER_SETTINGS_GET_COLLECTION: {
    name: "api_exchanger_settings_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_SETTINGS_GET_ITEM: { name: "api_exchanger_settings_get_item", hasFullAccess: true },
  API_PAIR_UNITS_GET_COLLECTION: { name: "api_pair_units_get_collection", hasFullAccess: true },
  API_PAIR_UNITS_GET_ITEM: { name: "api_pair_units_get_item", hasFullAccess: true },
  API_PAIR_UNITS_PUT_ITEM: { name: "api_pair_units_put_item", hasFullAccess: true },
  API_PAYMENT_SYSTEMS_GET_COLLECTION: {
    name: "api_payment_systems_get_collection",
    hasFullAccess: true,
  },
  API_PAYMENT_SYSTEMS_GET_ITEM: { name: "api_payment_systems_get_item", hasFullAccess: true },
  API_SERVERS_GET_COLLECTION: { name: "api_servers_get_collection", hasFullAccess: true },
  API_SERVERS_POST_COLLECTION: { name: "api_servers_post_collection", hasFullAccess: true },
  API_SERVERS_GET_ITEM: { name: "api_servers_get_item", hasFullAccess: true },
  API_SERVERS_PUT_ITEM: { name: "api_servers_put_item", hasFullAccess: true },
  API_SERVICES_GET_COLLECTION: { name: "api_services_get_collection", hasFullAccess: true },
  API_SERVICES_GET_ITEM: { name: "api_services_get_item", hasFullAccess: true },
  API_USERS_GET_COLLECTION: { name: "api_users_get_collection", hasFullAccess: true },
  API_USERS_GET_ITEM: { name: "api_users_get_item", hasFullAccess: true },
  API_USERS_PATCH_ITEM: { name: "api_users_patch_item", hasFullAccess: true },
  UPDATE_DATABASE_SCHEMA: { name: "update_database_schema", hasFullAccess: true },
  UPDATE_DATABASE_SCHEMA_FOR_ALL_EXCHANGERS: {
    name: "update_database_schema_for_all_exchangers",
    hasFullAccess: true,
  },
  FILL_BASE_CURRENCY_RATE_SERVICE: { name: "fill_base_currency_rate_service", hasFullAccess: true },
  FILL_EXCHANGER_RATE: { name: "fill_exchanger_rate", hasFullAccess: true },
  API_EXCHANGER_RATES_GET_COLLECTION: {
    name: "api_exchanger_rates_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_RATES_GET_ITEM: { name: "api_exchanger_rates_get_item", hasFullAccess: true },
  API_EXCHANGER_RATES_CHANGE_OUR_MARKUP_ITEM: {
    name: "api_exchanger_rates_change_our_markup_item",
    hasFullAccess: true,
  },
  API_USERS_CHANGE_PASSWORD: { name: "put_users_change_password", hasFullAccess: true },
  FILL_DEFAULT_HIDDEN_ATTRIBUTES_VALUE: {
    name: "fill_default_hidden_attributes_value",
    hasFullAccess: true,
  },
  FILL_BALANCE_REFILLING_DATA: {
    name: "fill_balance_refilling_data",
    hasFullAccess: true,
  },
  PUT_ENDPOINT_PERMISSION_EDIT_ACCESS: {
    name: "put_endpoint_permission_edit_access",
    hasFullAccess: true,
  },
  FILL_ENDPOINT_PERMISSIONS_FOR_NEW_USER: {
    name: "fill_endpoint_permissions_for_new_user",
    hasFullAccess: true,
  },
  API_BASE_CURRENCY_RATE_SERVICES_GET_COLLECTION: {
    name: "api_base_currency_rate_services_get_collection",
    hasFullAccess: true,
  },
  API_BASE_CURRENCY_RATE_SERVICES_GET_ITEM: {
    name: "api_base_currency_rate_services_get_item",
    hasFullAccess: true,
  },
  API_EXCHANGERS_GET_PROJECT_ID_ITEM: {
    name: "api_exchangers_get_project_id_item",
    hasFullAccess: true,
  },
  API_EXCHANGER_RATES_CHANGE_EXCHANGER_MARKUP_ITEM: {
    name: "api_exchanger_rates_change_exchanger_markup_item",
    hasFullAccess: true,
  },
  API_EXCHANGER_CHANGE_WIDGET_STATE_ITEM: {
    name: "api_exchangers_change_widget_state_item",
    hasFullAccess: true,
  },
};
