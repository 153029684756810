export default {
  switchPosition: "1px",
  primary: {
    first: "#4287D7",
    second: "#539BEE",
    third: "rgb(66, 135, 215, 0.1)",
    fourth: "rgb(66, 135, 215, 0.05)",
  },
  neutral: {
    first: "#333333",
    second: "#5C5C5C",
    third: "#6E6E6E",
    fourth: "#F2F2F2",
    // 959595 - 20%
    five: "rgba(149, 149, 149, 0.2)",
    // 656565 - 40%
    six: "rgba(101, 101, 101, 0.4)",
    // 6E6E6E - 50%
    seven: "rgba(110, 110, 110, 0.5)",
    eight: "rgba(149, 149, 149, 0.15)",
    tenth: "#959595",
    black: "#000000",
    white: "#FFFFFF",
  },

  info: {
    first: "#3192C9",
    // 3192C9 - 10%
    second: "rgb(49, 146, 201, 0.1)",
    third: "#ECF3FB",
  },

  warning: {
    first: "#E3B100",
    // E3B100 - 10%
    second: "rgb(227, 177, 0, 0.1)",
    third: "#FCF7E5",
  },

  success: {
    first: "#048F66",
    // 048F66 - 10%
    second: "rgb(4, 143, 102, 0.1)",
    third: "#E6F4F0",
  },

  danger: {
    first: "#DA1E28",
    // DA1E28 - 10%
    second: "rgba(218, 30, 40, 0.1)",
    third: "#FBE8E9",
  },

  //COMPONENT//
  siteBar: {
    backgroundColor: "#FFFFFF",
  },

  mainTemplateBackgroundColor: "#FFFFFF",
  headerBackgroundColor: "#333333",
};
