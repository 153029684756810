import { errorsParser } from "../../utils/errorsParser";
import {
  fetchExchangerCurrency,
  fetchExchangerPaymentSystem,
  fetchExchangerService,
  fetchMainCurrency,
} from "./asyncFilterHelpers";

const fetchExchangerPaymentSystemReducer = (builder) => {
  builder.addCase(fetchExchangerPaymentSystem.pending, (state) => {
    state.paymentSystem.loading = true;
  });
  builder.addCase(fetchExchangerPaymentSystem.fulfilled, (state, action) => {
    state.paymentSystem.list = action.payload.data;
    state.paymentSystem.loading = false;
  });
  builder.addCase(fetchExchangerPaymentSystem.rejected, (state, action) => {
    errorsParser(action.payload, true);
    state.paymentSystem.error = action.payload;
    state.paymentSystem.loading = false;
  });
};

const fetchExchangerCurrencyReducer = (builder) => {
  builder.addCase(fetchExchangerCurrency.pending, (state) => {
    state.currency.loading = true;
  });
  builder.addCase(fetchExchangerCurrency.fulfilled, (state, action) => {
    state.currency.list = action.payload.data;
    state.currency.loading = false;
  });
  builder.addCase(fetchExchangerCurrency.rejected, (state, action) => {
    errorsParser(action.payload, true);
    state.currency.error = action.payload;
    state.currency.loading = false;
  });
};

const fetchMainCurrencyReducer = (builder) => {
  builder.addCase(fetchMainCurrency.pending, (state) => {
    state.currency.loading = true;
  });
  builder.addCase(fetchMainCurrency.fulfilled, (state, action) => {
    state.currency.list = action.payload.data;
    state.currency.loading = false;
  });
  builder.addCase(fetchMainCurrency.rejected, (state, action) => {
    errorsParser(action.payload, true);
    state.currency.error = action.payload;
    state.currency.loading = false;
  });
};

const fetchExchangerServiceReducer = (builder) => {
  builder.addCase(fetchExchangerService.pending, (state) => {
    state.service.loading = true;
  });
  builder.addCase(fetchExchangerService.fulfilled, (state, action) => {
    state.service.list = action.payload.data;
    state.service.loading = false;
  });
  builder.addCase(fetchExchangerService.rejected, (state, action) => {
    errorsParser(action.payload, true);
    state.service.error = action.payload;
    state.service.loading = false;
  });
};

export {
  fetchExchangerCurrencyReducer,
  fetchExchangerPaymentSystemReducer,
  fetchExchangerServiceReducer,
  fetchMainCurrencyReducer,
};
