import React from "react";
import Skeleton from "react-loading-skeleton";

import PageHead from "../elements/pageHead/PageHead";
import Spinner from "../elements/spinner/Spinner";
import { StyledPageSpinner } from "../elements/spinner/styledSpinner";
import { StyledContainer } from "../styles/styledContainer";

const GeneralPageSkeleton = () => {
  return (
    <StyledContainer>
      <PageHead
        title={
          <Skeleton
            height={40}
            width={120}
            count={1}
            containerClassName="base-skeleton-wrapper"
            style={{ opacity: "0" }}
          />
        }
      />
      <StyledPageSpinner>
        <Spinner size="35px" />
      </StyledPageSpinner>
    </StyledContainer>
  );
};

export default GeneralPageSkeleton;
