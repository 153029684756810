import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const runSystemCommand = createAsyncThunk(
  "systemCommandsSlice/runSystemCommand",
  async ({ command }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        `/api/system-command/${command}`,
        {},
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

export { runSystemCommand };
