import { useState } from "react";

import { useAuthenticationGuard } from "../../utils/customHooks/useAuthenticationGuard";
import { usePermissionGuard } from "../../utils/customHooks/usePermissionGuard";
import Header from "./header/Header";
import PageWrapper from "./PageWrapper";
import { StyledLayoutWrapper } from "./styledLayout";
import UserPageWrapper from "./UserPageWrapper";

const Layout = ({ authenticated, isAccountPathname }) => {
  const [collapsed, setCollapsed] = useState(eval(localStorage.getItem("collapsed")));
  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(false);

  useAuthenticationGuard();

  // useRouterGuard(authenticated, routers);

  usePermissionGuard();

  const handleCloseSideBar = () => {
    setCollapsed((prev) => {
      localStorage.setItem("collapsed", String(!prev));

      return !prev;
    });

    setToggled(false);
  };

  const toggleMobile = () => {
    setToggled((prev) => !prev);
    setCollapsed(false);
  };

  return (
    <>
      <Header
        authenticated={authenticated}
        isAccountPathname={isAccountPathname}
        toggled={toggled}
        collapsed={collapsed}
        handleCloseSideBar={handleCloseSideBar}
        toggleMobile={toggleMobile}
      />
      <StyledLayoutWrapper changeWidth={toggled}>
        {isAccountPathname ? (
          <UserPageWrapper
            collapsed={collapsed}
            toggled={toggled}
            setToggled={setToggled}
            broken={broken}
            setBroken={setBroken}
          />
        ) : (
          <PageWrapper />
        )}
      </StyledLayoutWrapper>
    </>
  );
};

export default Layout;
