import { createSlice } from "@reduxjs/toolkit";

import { getWidgetSettingsReducer } from "./widgetReducer";

const initialState = {
  widgetSettings: {},
  errors: null,
  loading: true,
};

const widgetSlice = createSlice({
  name: "widget",
  initialState,
  reducers: {
    clearStateWidgetSettings: (state) => {
      state.widgetSettings = {};
      state.loading = true;
      state.errors = null;
    },
  },
  extraReducers: (builder) => {
    getWidgetSettingsReducer(builder);
  },
});

export const { clearStateWidgetSettings } = widgetSlice.actions;

export default widgetSlice.reducer;
