import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";

const refTokenName = "refToken";
const widgetTokenName = "widgetToken";

function useRefToken() {
  const location = useLocation();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [_, setCookie, removeCookie] = useCookies();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const refToken = params.get(refTokenName);
    const widgetToken = params.get(widgetTokenName);

    if (refToken || widgetToken) {
      if (refToken) {
        setCookie(refTokenName, refToken);
        removeCookie(widgetTokenName);
        params.delete(refTokenName);
      }
      if (widgetToken) {
        setCookie(widgetTokenName, widgetToken);
        removeCookie(refTokenName);
        params.delete(widgetTokenName);
      }

      navigate({ search: params.toString() }, { replace: true });
    }
  }, [location.search, navigate]);
}

export default useRefToken;
