import styled from "styled-components";

const colorButton = {
  color: String,
  border: Boolean,
};

const styleButton = (color, border, theme) => {
  switch (color) {
    case "main":
      return `
        color: ${border ? theme.neutral.first : theme.neutral.white};
        background-color: ${border ? "transparent" : theme.primary.first};
        border: 1px solid ${border ? theme.primary.first : theme.primary.first};
         &:hover {
          background-color: ${border ? theme.primary.third : theme.primary.third};
          color: ${theme.neutral.first};
          border-color: ${border ? theme.primary.third : "transparent"};
        }
     `;
    case "white":
      return `
        color: ${border ? theme.neutral.first : theme.neutral.first};
        background-color: ${border ? "transparent" : theme.neutral.white};
        border: 1px solid ${border ? theme.primary.first : theme.neutral.white};
         &:hover {
          background-color: ${border ? theme.primary.third : theme.primary.third};
          color: ${theme.neutral.first};
          border-color: ${border ? theme.primary.third : "transparent"};
        }
     `;
    case "info":
      return `
        color: ${border ? theme.neutral.first : theme.neutral.white};
        background-color: ${border ? "transparent" : theme.info.first};
        border: 1px solid ${border ? theme.info.first : theme.info.first};
         &:hover {
          background-color: ${border ? theme.info.second : theme.info.second};
          color: ${theme.neutral.first};
          border-color: ${border ? theme.info.second : "transparent"};
        }
     `;
    case "warning":
      return `
        color: ${border ? theme.neutral.first : theme.neutral.white};
        background-color: ${border ? "transparent" : theme.warning.first};
        border: 1px solid ${border ? theme.warning.first : theme.warning.first};
         &:hover {
          background-color: ${border ? theme.warning.second : theme.warning.second};
          color: ${theme.neutral.first};
          border-color: ${border ? theme.warning.second : "transparent"};
        }
     `;
    case "success":
      return `
        color: ${border ? theme.neutral.first : theme.neutral.white};
        background-color: ${border ? "transparent" : theme.success.first};
        border: 1px solid ${border ? theme.success.first : theme.success.first};
         &:hover {
          background-color: ${border ? theme.success.second : theme.success.second};
          color: ${theme.neutral.first};
          border-color: ${border ? theme.success.second : "transparent"};
        }
     `;
    case "danger":
      return `
        color: ${border ? theme.neutral.first : theme.neutral.white};
        background-color: ${border ? "transparent" : theme.danger.first};
        border: 1px solid ${border ? theme.danger.first : theme.danger.first};
         &:hover {
          background-color: ${border ? theme.danger.second : theme.danger.second};
          color: ${theme.neutral.first};
          border-color: ${border ? theme.danger.second : "transparent"};
        }
     `;
    default:
      return `
        color: ${border ? theme.neutral.first : theme.neutral.white};
        background-color: ${border ? "transparent" : theme.primary.first};
        border: 1px solid ${border ? theme.primary.first : theme.primary.first};
         &:hover {
          background-color: ${border ? theme.primary.third : theme.primary.third};
          color: ${theme.neutral.first};
          border-color: ${border ? theme.primary.third : "transparent"};
        }
     `;
  }
};

export const StyledButton = styled("button", colorButton)`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  ${({ mr }) => mr && `margin-right: ${mr}px`};
  ${({ ml }) => ml && `margin-left: ${ml}px`};
  ${({ width }) =>
    width &&
    `
  max-width: ${width}px;
  width: 100%`};
  padding: ${({ padding, figure }) => padding || (figure === "circle" ? "12px" : "9px 17px")};
  font-size: 14px;
  font-weight: ${({ weight }) => weight || "500"};
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ figure }) => (figure === "circle" ? "50%" : "12px")};
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
    transform: scale(1) !important;
  `};
  ${({ withIcon }) => withIcon && "gap: 6px;"};

  ${({ color, border, theme }) => styleButton(color, border, theme)}
  &:active {
    transform: scale(0.95) translateZ(0);
  }
`;
