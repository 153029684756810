import { StyledBreakWord } from "../../styles/StyledBreakWord";
import {
  StyledChildrenContainer,
  StyledMobilePagePrevious,
  StyledPageHeadWrapper,
  StyledPagePrevious,
  StyledPageTitle,
  StyledPageTitleContainer,
} from "./styledPageHead";

export const PageHead = ({ title, previous, previousLink, children, pb, actionsInside }) => {
  return (
    <StyledPageHeadWrapper pb={pb}>
      {previous && (
        <StyledMobilePagePrevious to={previousLink}>
          <span className="icon-chevron-left" />
          {previous}
        </StyledMobilePagePrevious>
      )}
      <StyledPageTitleContainer actionsInside={actionsInside}>
        {previous && <StyledPagePrevious to={previousLink}>{previous}</StyledPagePrevious>}
        {title && (
          <StyledPageTitle>
            <StyledBreakWord>{title}</StyledBreakWord>
          </StyledPageTitle>
        )}
      </StyledPageTitleContainer>

      <StyledChildrenContainer>{children}</StyledChildrenContainer>
    </StyledPageHeadWrapper>
  );
};

export default PageHead;
