import routes from "../../routes/routes";
import userRoutesConcat from "../../routes/userRoutes";

const checkPrivateRouters = (authenticated) => {
  if (!authenticated) {
    return {
      routers: routes,
    };
  }

  return {
    routers: userRoutesConcat,
  };
};

export { checkPrivateRouters };
