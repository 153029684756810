import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const fetchBalances = createAsyncThunk(
  "balancesSlice/fetchBalances",
  async ({ filterUrl }, thunkAPI) => {
    try {
      const url = filterUrl ? `/api/balances${filterUrl}` : "/api/balances";
      const response = await axiosInstance(url, userAuthenticationConfig());

      return {
        data: response.data["hydra:member"],
        paginationInfo: {
          totalPageCount: response.data["hydra:totalPageCount"],
          totalItems: response.data["hydra:totalItems"],
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchExchangerBalances = createAsyncThunk(
  "balancesSlice/fetchExchangerBalances",
  async ({ filterUrl }, thunkAPI) => {
    try {
      const url = filterUrl
        ? `/api/main-exchanger-balance${filterUrl}`
        : "/api/main-exchanger-balance";
      const response = await axiosInstance(url, userAuthenticationConfig());

      return {
        data: response.data.balances,
        paginationInfo: {
          totalPageCount: response.data.totalPageCount,
          totalItems: response.data.totalItems,
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data);
    }
  }
);

export { fetchBalances, fetchExchangerBalances };
