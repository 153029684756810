import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { errorsParser } from "../../utils/errorsParser";
import {
  changeExchangerMarkup,
  changeMerchantExchangerMarkup,
  changeOurMarkup,
  fetchBaseCurrencyRates,
  fetchExchangerRates,
  fetchMainExchangerRates,
  fetchMerchantExchangerRates,
} from "./asyncRatesSlice";

const fetchBaseCurrencyRatesReducer = (builder) => {
  builder.addCase(fetchBaseCurrencyRates.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchBaseCurrencyRates.fulfilled, (state, action) => {
    state.baseCurrenciesRates = action.payload.data;
    state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
    state.loading = false;
  });
  builder.addCase(fetchBaseCurrencyRates.rejected, (state, action) => {
    closableNotification(action.payload, "error");
    state.loading = false;
  });
};

const fetchMainExchangerRatesReducer = (builder) => {
  builder.addCase(fetchMainExchangerRates.pending, (state) => {
    state.loading = true;
    state.paginationInfo = { ...state.paginationInfo, totalPageCount: null };
  });
  builder.addCase(fetchMainExchangerRates.fulfilled, (state, action) => {
    state.rates = action.payload.data;
    state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
    state.loading = false;
  });
  builder.addCase(fetchMainExchangerRates.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const fetchExchangerRatesReducer = (builder) => {
  builder.addCase(fetchExchangerRates.pending, (state) => {
    state.loading = true;
    state.paginationInfo = { ...state.paginationInfo, totalPageCount: null };
  });
  builder.addCase(fetchExchangerRates.fulfilled, (state, action) => {
    state.exchangerRates = action.payload.data;
    state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
    state.loading = false;
  });
  builder.addCase(fetchExchangerRates.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const fetchMerchantExchangerRatesReducer = (builder) => {
  builder.addCase(fetchMerchantExchangerRates.pending, (state) => {
    state.loading = true;
    state.paginationInfo = { ...state.paginationInfo, totalPageCount: null };
  });
  builder.addCase(fetchMerchantExchangerRates.fulfilled, (state, action) => {
    state.exchangerRates = action.payload.data;
    state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
    state.loading = false;
  });
  builder.addCase(fetchMerchantExchangerRates.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const changeOurMarkupReducer = (builder) => {
  builder.addCase(changeOurMarkup.pending, (state, action) => {
    const { name, id } = action.meta.arg;

    if (name === "ourMarkupPurchase") {
      state.itemLoadingState.ourMarkupPurchase = true;
    } else if (name === "ourMarkupSelling") {
      state.itemLoadingState.ourMarkupSelling = true;
    }

    state.editingItem = id;
  });
  builder.addCase(changeOurMarkup.fulfilled, (state, action) => {
    const data = action.payload.data;

    state.rates = state.rates.map((item) => {
      if (item.id === data.id) {
        return data;
      }
      return item;
    });

    state.itemLoadingState = {
      ourMarkupPurchase: false,
      ourMarkupSelling: false,
    };

    state.editingItem = null;

    closableNotification("Percent successfully changed", "success");
  });
  builder.addCase(changeOurMarkup.rejected, (state, action) => {
    errorsParser(action.payload);
    state.itemLoadingState = {
      ourMarkupPurchase: false,
      ourMarkupSelling: false,
    };
    state.editingItem = null;
  });
};

const changeExchangerMarkupReducer = (builder) => {
  builder.addCase(changeExchangerMarkup.pending, (state, action) => {
    const { id } = action.meta.arg;

    if (name === "exchangerMarkupSelling") {
      state.itemLoadingState.exchangerMarkupSelling = true;
    } else if (name === "exchangerMarkupPurchase") {
      state.itemLoadingState.exchangerMarkupPurchase = true;
    }

    state.editingItem = id;
  });
  builder.addCase(changeExchangerMarkup.fulfilled, (state, action) => {
    const data = action.payload.data;

    state.rates = state.rates.map((item) => {
      if (item.id === data.id) {
        return data;
      }
      return item;
    });

    state.itemLoadingState = {
      ourMarkupPurchase: false,
      ourMarkupSelling: false,
      exchangerMarkupPurchase: false,
      exchangerMarkupSelling: false,
    };

    state.editingItem = null;

    closableNotification("Percent successfully changed", "success");
  });
  builder.addCase(changeExchangerMarkup.rejected, (state, action) => {
    errorsParser(action.payload);
    state.itemLoadingState = {
      ourMarkupPurchase: false,
      ourMarkupSelling: false,
      exchangerMarkupPurchase: false,
      exchangerMarkupSelling: false,
    };
    state.editingItem = null;
  });
};

const changeMerchantExchangerMarkupReducer = (builder) => {
  builder.addCase(changeMerchantExchangerMarkup.pending, (state, action) => {
    const { id } = action.meta.arg;

    if (name === "exchangerMarkupSelling") {
      state.itemLoadingState.exchangerMarkupSelling = true;
    } else if (name === "exchangerMarkupPurchase") {
      state.itemLoadingState.exchangerMarkupPurchase = true;
    }

    state.editingItem = id;
  });
  builder.addCase(changeMerchantExchangerMarkup.fulfilled, (state, action) => {
    const data = action.payload.data;

    state.rates = state.rates.map((item) => {
      if (item.id === data.id) {
        return data;
      }
      return item;
    });

    state.itemLoadingState = {
      exchangerMarkupPurchase: false,
      exchangerMarkupSelling: false,
    };

    state.editingItem = null;

    closableNotification("Percent successfully changed", "success");
  });
  builder.addCase(changeMerchantExchangerMarkup.rejected, (state, action) => {
    errorsParser(action.payload);
    state.itemLoadingState = {
      exchangerMarkupPurchase: false,
      exchangerMarkupSelling: false,
    };
    state.editingItem = null;
  });
};

export {
  changeExchangerMarkupReducer,
  changeMerchantExchangerMarkupReducer,
  changeOurMarkupReducer,
  fetchBaseCurrencyRatesReducer,
  fetchExchangerRatesReducer,
  fetchMainExchangerRatesReducer,
  fetchMerchantExchangerRatesReducer,
};
