import jwt_decode from "jwt-decode";

const getUserInfo = () => {
  let token = null;

  let endpoints = null;

  if (localStorage.getItem("endpoints")) {
    endpoints = JSON.parse(localStorage.getItem("endpoints")).endpoints;
  }

  if (localStorage.getItem("token") !== "") {
    try {
      token = jwt_decode(localStorage.getItem("token"));

      if (endpoints) {
        token.endpoints = endpoints;
      }
    } catch (e) {
      return null;
    }
    return token;
  }

  return null;
};

export default getUserInfo;
