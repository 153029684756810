import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import { StyledButton } from "../../styles/styledButton";
import CustomLink from "../customLink/CustomLink";
import navigationRoutes from "./navigationRoutes";
import {
  StyledMenuList,
  StyledMobileNavigation,
  StyledNavigationContent,
  StyledNavigationWrapper,
} from "./styledNavigation";

const Navigation = ({ t, tReady, authenticated, userAccountLink }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const siteUrl = window.location.origin;

  const path = baseURL && baseURL !== siteUrl ? "" : process.env.REACT_APP_LANDING_DOMAIN;

  const [show, setShow] = useState(false);

  const blockedBody = () => {
    if (show) {
      return (document.body.style.cssText = `
        touch-action: none;
        overflow: hidden;
      `);
    }

    document.body.removeAttribute("style");
  };

  useEffect(() => {
    blockedBody();
  }, [show]);

  const toggleMenu = () => {
    setShow(!show);
  };

  const closeMenu = () => {
    setShow(false);
  };

  if (!tReady) {
    return (
      <StyledMobileNavigation>
        <div className="navbar-top" />
        <div className="navbar-middle" />
        <div className="navbar-bottom" />
      </StyledMobileNavigation>
    );
  }

  return (
    <StyledNavigationWrapper>
      {/*<StyledMobileNavigation authenticated={authenticated} show={show} onClick={toggleMenu}>*/}
      {/*  <div className="navbar-top" />*/}
      {/*  <div className="navbar-middle" />*/}
      {/*  <div className="navbar-bottom" />*/}
      {/*</StyledMobileNavigation>*/}
      {/*<StyledNavigationContent show={show}>*/}
      {/*  <StyledMenuList show={show}>*/}
      {/*    <li>*/}
      {/*      <div>*/}
      {/*        <StyledButton*/}
      {/*          color="main"*/}
      {/*          border={true}*/}
      {/*          as={CustomLink}*/}
      {/*          to={userAccountLink()}*/}
      {/*          onClick={closeMenu}*/}
      {/*        >*/}
      {/*          {authenticated ? "My account" : "Sign in"}*/}
      {/*        </StyledButton>*/}
      {/*      </div>*/}
      {/*    </li>*/}
      {/*    {navigationRoutes.map((route, key) => (*/}
      {/*      <li key={key}>*/}
      {/*        <a href={`${path}${route.path}`} onClick={closeMenu}>*/}
      {/*          {t(route.title)}*/}
      {/*        </a>*/}
      {/*      </li>*/}
      {/*    ))}*/}
      {/*  </StyledMenuList>*/}
      {/*</StyledNavigationContent>*/}
    </StyledNavigationWrapper>
  );
};

export default withTranslation("translation", { keyPrefix: "navigation" })(Navigation);
