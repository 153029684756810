import { closableNotification } from "../../../components/elements/notification/ClosableNotification";
import { errorsParser } from "../../../utils/errorsParser";
import {
  changeMyExchangerAvailableServices,
  changeMyExchangerInputData,
  changeMyExchangerPairUnit,
  changeMyExchangerPairUnitActivity,
  changePairUnitActivity,
  fetchMyExchangerAvailableServices,
  fetchPairUnits,
  fetchUserPairUnits,
} from "./asyncPairUnitSlice";

const fetchPairUnitsReducer = (builder) => {
  builder.addCase(fetchPairUnits.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchPairUnits.fulfilled, (state, action) => {
    state.pairUnits = action.payload.data;
    state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
    state.loading = false;
  });
  builder.addCase(fetchPairUnits.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const changePairUnitActivityReducer = (builder) => {
  builder.addCase(changePairUnitActivity.pending, (state) => {
    state.changeActivityState.loading = true;
  });
  builder.addCase(changePairUnitActivity.fulfilled, (state, action) => {
    const newObjectPairUnit = action.payload.data;

    state.pairUnits = state.pairUnits.map((item) => {
      if (item.id === newObjectPairUnit.id) {
        return {
          ...item,
          basePairUnitId: item.id,
          isActive: newObjectPairUnit.isActive,
        };
      }

      return item;
    });

    closableNotification("Activity has been changed", "success");
    state.errors = null;
    state.changeActivityState.loading = false;
  });
  builder.addCase(changePairUnitActivity.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeActivityState.loading = false;
  });
};

const changeMyExchangerPairUnitActivityReducer = (builder) => {
  builder.addCase(changeMyExchangerPairUnitActivity.pending, (state) => {
    state.changeActivityState.loading = true;
  });
  builder.addCase(changeMyExchangerPairUnitActivity.fulfilled, (state, action) => {
    const newObjectPairUnit = action.payload.data.data[0];

    state.pairUnits = state.pairUnits.map((item) => {
      if (item.id === newObjectPairUnit.id) {
        return {
          ...item,
          exchangerPairUnitId: newObjectPairUnit.exchangerPairUnitId,
          isActive: newObjectPairUnit.isActive,
          fee: newObjectPairUnit.fee,
        };
      }

      return item;
    });

    closableNotification("Payment system activity has been changed", "success");
    state.errors = null;
    state.changeActivityState.loading = false;
  });
  builder.addCase(changeMyExchangerPairUnitActivity.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeActivityState.loading = false;
  });
};

const changeMyExchangerInputDataReducer = (builder) => {
  builder.addCase(changeMyExchangerInputData.pending, (state) => {
    state.changeDataState.loading = true;
  });
  builder.addCase(changeMyExchangerInputData.fulfilled, (state) => {
    state.changeDataState.loading = false;
    closableNotification("Value successfully updated", "success");
  });
  builder.addCase(changeMyExchangerInputData.rejected, (state, action) => {
    state.changeDataState.loading = false;
    errorsParser(action.payload);
  });
};

const fetchUserPairUnitsReducer = (builder) => {
  builder.addCase(fetchUserPairUnits.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchUserPairUnits.fulfilled, (state, action) => {
    state.pairUnits = action.payload.data;
    state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
    state.loading = false;
  });
  builder.addCase(fetchUserPairUnits.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const fetchMyExchangerAvailableServicesReducer = (builder) => {
  builder.addCase(fetchMyExchangerAvailableServices.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchMyExchangerAvailableServices.fulfilled, (state, action) => {
    state.availableServices = action.payload.data;
  });
  builder.addCase(fetchMyExchangerAvailableServices.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const changeMyExchangerPairUnitReducer = (builder) => {
  builder.addCase(changeMyExchangerPairUnit.pending, (state) => {
    state.changeDataState.loading = true;
  });
  builder.addCase(changeMyExchangerPairUnit.fulfilled, (state) => {
    state.changeDataState.loading = false;
    closableNotification("Value successfully updated", "success");
  });
  builder.addCase(changeMyExchangerPairUnit.rejected, (state, action) => {
    state.changeDataState.loading = false;
    errorsParser(action.payload);
  });
};

const changeMyExchangerAvailableServicesReducer = (builder) => {
  builder.addCase(changeMyExchangerAvailableServices.pending, (state) => {
    state.changeAvailableServices.loading = true;
  });
  builder.addCase(changeMyExchangerAvailableServices.fulfilled, (state) => {
    closableNotification("Rate service has been changed", "success");
    state.errors = null;
    state.changeAvailableServices.loading = false;
  });
  builder.addCase(changeMyExchangerAvailableServices.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeAvailableServices.loading = false;
  });
};

export {
  changeMyExchangerAvailableServicesReducer,
  changeMyExchangerInputDataReducer,
  changeMyExchangerPairUnitActivityReducer,
  changeMyExchangerPairUnitReducer,
  changePairUnitActivityReducer,
  fetchMyExchangerAvailableServicesReducer,
  fetchPairUnitsReducer,
  fetchUserPairUnitsReducer,
};
