import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";

const fetchThemeConfig = createAsyncThunk("themeSlice/fetchThemeConfig", async (_, thunkAPI) => {
  try {
    const response = await axiosInstance.get("/api/exchanger-current-settings");

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export { fetchThemeConfig };
