import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { errorsParser } from "../../utils/errorsParser";
import { changePairActivity, changePairPercent, fetchPairs } from "./asyncPairsSlice";

const fetchPairsReducer = (builder) => {
  builder.addCase(fetchPairs.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchPairs.fulfilled, (state, action) => {
    state.pairs = action.payload.data;
    state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
    state.loading = false;
  });
  builder.addCase(fetchPairs.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const changePairPercentReducer = (builder) => {
  builder.addCase(changePairPercent.pending, (state) => {
    state.changePercentState.loading = true;
  });
  builder.addCase(changePairPercent.fulfilled, (state, action) => {
    const newObjectPair = action.payload.data;

    state.pairs = state.pairs.map((item) => {
      if (item.id === newObjectPair.id) {
        return {
          ...item,
          percent: newObjectPair.percent,
        };
      }
      return item;
    });

    closableNotification("Percent has been changed", "success");
    state.errors = null;
    state.changePercentState.loading = false;
  });
  builder.addCase(changePairPercent.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changePercentState.loading = false;
  });
};

const changePairActivityReducer = (builder) => {
  builder.addCase(changePairActivity.pending, (state) => {
    state.changeActivityState.loading = true;
  });
  builder.addCase(changePairActivity.fulfilled, (state, action) => {
    const newObjectPair = action.payload.data;

    state.pairs = state.pairs.map((item) => {
      if (item.id === newObjectPair.id) {
        return {
          ...item,
          isActive: newObjectPair.isActive,
        };
      }

      return item;
    });

    closableNotification("Activity has been changed", "success");
    state.errors = null;
    state.changeActivityState.loading = false;
  });
  builder.addCase(changePairActivity.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeActivityState.loading = false;
  });
};

export { changePairActivityReducer, changePairPercentReducer, fetchPairsReducer };
