import { SkeletonTheme } from "react-loading-skeleton";
import { useTheme } from "styled-components";

import { StyledSkeletonBlock } from "./styledSkeleton";

const SkeletonMainBlock = ({ children }) => {
  const theme = useTheme();

  return (
    <StyledSkeletonBlock>
      <SkeletonTheme baseColor={theme.neutral.fourth} highlightColor={theme.neutral.fourth}>
        {children}
      </SkeletonTheme>
    </StyledSkeletonBlock>
  );
};

export default SkeletonMainBlock;
