import { closableNotification } from "../components/elements/notification/ClosableNotification";

/**
 * @param errorData string | errors
 * @param setErrors boolean
 */
const errorsParser = (errorData, setErrors) => {
  // for string
  if (typeof errorData === "string" && Object.keys(JSON.parse(errorData)).length === 0) {
    closableNotification(errorData, "error");
    return;
  }

  // for json parsed string
  if (typeof errorData === "string") {
    try {
      return JSON.parse(errorData);
    } catch (err) {
      closableNotification("Some error with parse errors", "error");
    }
  }

  // return errors array
  if (errorData?.errors && Array.isArray(errorData.errors) && setErrors) {
    return errorData.errors.reduce((acc, err) => {
      const [key, value] = Object.entries(err)[0];

      acc[key] = value;

      return acc;
    }, {});
  }

  // for array of objects
  if (
    errorData?.errors &&
    Array.isArray(errorData.errors) &&
    typeof errorData.errors[0] === "object" &&
    Object.keys(errorData.errors[0]).length > 0
  ) {
    const messageError = Object.values(errorData.errors[0])[0];
    closableNotification(messageError, "error");
    return;
  }

  // for array of strings
  if (errorData?.errors && Array.isArray(errorData.errors)) {
    const messageError = errorData.errors[0];
    closableNotification(messageError, "error");
    return;
  }

  // for object
  if (errorData?.errors && Object.keys(errorData.errors).length > 0) {
    const messageError = Object.values(errorData.errors)[0];
    closableNotification(messageError, "error");
    return;
  }

  // todo check usage
  if (errorData?.errors) {
    const newErrorsFormat = errorData.errors.reduce((acc, cur) => {
      const [key, value] = Object.entries(cur)[0];
      return { ...acc, [key]: value };
    }, {});
    return newErrorsFormat;
  }

  // todo check usage
  if (errorData?.data && errorData.data?.errors) {
    const newErrorsFormat = errorData.data.errors.reduce((acc, cur) => {
      const [key, value] = Object.entries(cur)[0];
      const newKey = key.replaceAll("[", "").replaceAll("]", "");

      return { ...acc, [newKey]: value };
    }, {});

    return newErrorsFormat;
  }

  return errorData;
};

export { errorsParser };
