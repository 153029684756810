import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const fetchExchangerPaymentSystem = createAsyncThunk(
  "filterHelpers/fetchExchangerPaymentSystem",
  async (thunkAPI) => {
    try {
      const response = await axiosInstance(
        "/api/exchanger-payment-system?pagination=false",
        userAuthenticationConfig()
      );

      return {
        data: response.data.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const fetchExchangerCurrency = createAsyncThunk(
  "filterHelpers/fetchExchangerCurrency",
  async (thunkAPI) => {
    try {
      const response = await axiosInstance(
        "/api/exchanger-currency?pagination=false",
        userAuthenticationConfig()
      );

      return {
        data: response.data.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const fetchMainCurrency = createAsyncThunk("filterHelpers/fetchMainCurrency", async (thunkAPI) => {
  try {
    const response = await axiosInstance(
      "/api/currencies?pagination=false",
      userAuthenticationConfig()
    );

    return {
      data: response.data["hydra:member"],
    };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data);
  }
});

const fetchExchangerService = createAsyncThunk(
  "filterHelpers/fetchExchangerService",
  async (thunkAPI) => {
    try {
      const response = await axiosInstance(
        "/api/exchanger-service?pagination=false",
        userAuthenticationConfig()
      );

      return {
        data: response.data.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

export {
  fetchExchangerCurrency,
  fetchExchangerPaymentSystem,
  fetchExchangerService,
  fetchMainCurrency,
};
