import { createSlice } from "@reduxjs/toolkit";

import {
  changePairActivityReducer,
  changePairPercentReducer,
  fetchPairsReducer,
} from "./pairsReducers";

const initialState = {
  pairs: [],
  error: null,
  loading: true,
  changePercentState: {
    loading: false,
  },
  changeActivityState: {
    loading: false,
  },
  paginationInfo: {
    totalItems: null,
    totalPageCount: null,
    itemsPerPage: null,
  },
};

const pairsSlice = createSlice({
  name: "pairsSlice",
  initialState,
  reducers: {
    setPaginationInfo: (state, action) => {
      state.paginationInfo = action.payload;
    },
    clearPairStore: (state) => {
      state.pairs = [];
    },
  },
  extraReducers: (builder) => {
    changePairActivityReducer(builder);
    changePairPercentReducer(builder);
    fetchPairsReducer(builder);
  },
});

export const { setPaginationInfo, clearPairStore } = pairsSlice.actions;

export default pairsSlice.reducer;
