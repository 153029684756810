import { useMatch } from "react-router-dom";

import { StyledTextRegular } from "../../styles/styledTypography";
import { StyledSidebarSubLinkItem } from "./styledSidebar";

const SidebarItemSubLink = ({ title, path, language, as }) => {
  const match = useMatch({
    path: `${language}${path}`,
  });

  return (
    <StyledSidebarSubLinkItem active={match} to={path} as={as}>
      <StyledTextRegular>{title}</StyledTextRegular>
    </StyledSidebarSubLinkItem>
  );
};

export default SidebarItemSubLink;
