import { defaultItemsPerPage } from "../consts";

/**
 * Returns `itemsPerPage` from `paginationInfo` or the URL query parameter.
 *
 * @param {Object} paginationInfo - Pagination info, may contain `itemsPerPage`.
 * @returns {Object} Object with the `itemsPerPage` value, or a default.
 * @example
 * const filterUrl = {...getItemsPerPage(paginationInfo)};
 */

export const getItemsPerPage = (paginationInfo) => {
  if (!paginationInfo?.itemsPerPage) {
    const params = new URLSearchParams(window.location.search);
    const itemsPerPage = params.get("itemsPerPage");

    return { itemsPerPage: itemsPerPage || defaultItemsPerPage };
  }

  return { itemsPerPage: paginationInfo.itemsPerPage };
};
