import { createSlice } from "@reduxjs/toolkit";

import {
  fetchExchangerCurrencyReducer,
  fetchExchangerPaymentSystemReducer,
  fetchExchangerServiceReducer,
  fetchMainCurrencyReducer,
} from "./filterHelpersReducers";

const initialState = {
  paymentSystem: {
    list: [],
    loading: false,
    error: null,
  },
  currency: {
    list: [],
    loading: false,
    error: null,
  },
  service: {
    list: [],
    loading: false,
    error: null,
  },
};

const filterHelpersSlice = createSlice({
  name: "filterHelpers",
  initialState,
  reducers: {
    clearFilterHelpersStore: (state) => {
      state.paymentSystem = {};
      state.currency = {};
      state.service = {};
    },
  },
  extraReducers: (builder) => {
    fetchExchangerPaymentSystemReducer(builder);
    fetchExchangerCurrencyReducer(builder);
    fetchExchangerServiceReducer(builder);
    fetchMainCurrencyReducer(builder);
  },
});

export const { clearFilterHelpersStore } = filterHelpersSlice.actions;

export default filterHelpersSlice.reducer;
