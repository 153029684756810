import React from "react";
import { Navigate } from "react-router-dom";

import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { defaultLanguage, typeAccountRoutes } from "../consts";
import getUserInfo from "../getUserInfo";
import { handleLogout } from "../../store/auth/authSlice";
import { removeUserInfo } from "../../store/user/userSlice";

const PUBLIC_PERMISSION = { name: "public", hasFullAccess: true };

function withPermissionCheckPage(WrappedComponent, permissions, cookiesLang, checkFullAccess, dispatch) {
  // eslint-disable-next-line react/display-name
  return function (props) {
    const propsWithPermissions = {
      ...props,
      permissions,
    };

    if (!permissions || permissions.some((perm) => perm.name === PUBLIC_PERMISSION.name)) {
      return <WrappedComponent {...propsWithPermissions} />;
    }

    const userPermissions = getUserInfo()?.endpoints;

    if (!userPermissions) {
      localStorage.removeItem("token");
      dispatch(handleLogout());
      dispatch(removeUserInfo());

      return closableNotification("Oops! Something went wrong. Please log in again to continue.", "error");
    }

    const hasPermission = permissions.some((perm) =>
      userPermissions.some((userPermission) => userPermission.name === perm.name)
    );

    const isHaveFullAccess = checkFullAccess
      ? permissions.some((perm) =>
          userPermissions.some(
            (userPermission) =>
              userPermission.name === perm.name &&
              userPermission.hasFullAccess === perm.hasFullAccess
          )
        )
      : true;

    if (!hasPermission || !isHaveFullAccess) {
      return (
        <Navigate
          to={`${cookiesLang !== defaultLanguage ? `/${cookiesLang}` : ""}${typeAccountRoutes.CRM}/page-not-found`}
          replace={true}
        />
      );
    }

    return <WrappedComponent {...propsWithPermissions} />;
  };
}

export default withPermissionCheckPage;
