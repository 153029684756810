import { useState } from "react";
import { withTranslation } from "react-i18next";
import { Sidebar } from "react-pro-sidebar";

import eventBus from "../../../utils/eventBus";
import { StyledSpan, StyledTextRegular } from "../../styles/styledTypography";
import SidebarLinkList from "./SidebarLinkList";
import {
  StyledSidebar,
  StyledSidebarItem,
  StyledSidebarLink,
  StyledSidebarSiteLinks,
} from "./styledSidebar";

const SidebarContainer = ({ collapsed, toggled, setToggled, setBroken, t, tReady }) => {
  const [animateOpen, setAnimateOpen] = useState(null);

  if (!tReady) {
    return <></>;
  }

  return (
    <Sidebar
      width="258px"
      collapsedWidth="105px"
      className="sidebar-aside"
      breakPoint="lg"
      collapsed={collapsed}
      toggled={toggled}
      onBackdropClick={() => setToggled(false)}
      onBreakPoint={setBroken}
    >
      <StyledSidebar>
        <StyledSidebarSiteLinks>
          <SidebarLinkList
            setAnimateOpen={setAnimateOpen}
            animateOpen={animateOpen}
            t={t}
            setToggled={setToggled}
            collapsed={collapsed}
            toggled={toggled}
          />
          {/*<StyledSidebarItem>
          <StyledSidebarLink
            as={CustomLink}
            to={`${typeAccountRoutes.ACCOUNT}/settings`}
            collapsed={collapsed}
          >
            <StyledSpan fontSize="24" className="icon-settings" />
            <StyledTextRegular>
              {t("settings")}
            </StyledTextRegular>
          </StyledSidebarLink>
        </StyledSidebarItem>*/}
        </StyledSidebarSiteLinks>
        <StyledSidebarItem>
          <StyledSidebarLink
            as="button"
            onClick={() => {
              eventBus.dispatch("logout");
            }}
            collapsed={collapsed}
          >
            <StyledSpan fontSize="24" className="icon-logout" />
            <StyledTextRegular>{t("logout")}</StyledTextRegular>
          </StyledSidebarLink>
        </StyledSidebarItem>
      </StyledSidebar>
    </Sidebar>
  );
};

export default withTranslation("translation", { keyPrefix: "sidebar" })(SidebarContainer);
