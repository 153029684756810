const crmSidebar = {
  TRANSACTION: "transaction:crm-sidebar",
};

const transactions = {
  ADMIN: "transactions:admin",
  MERCHANT: "transactions:merchant",
};

const transactionDetails = {
  ADMIN: "transactions:admin",
  MERCHANT: "transactions:merchant",
};

export { crmSidebar, transactionDetails, transactions };
