import styled from "styled-components";

import { StyledSelect } from "./styledSelect";

export const StyledFilterWrapperOld = styled.div`
  ${({ pt }) => pt && `padding-top: ${pt}px`};
  ${({ pb }) => pb && `padding-bottom: ${pb}px`};
  position: relative;
`;

export const StyledFilterHead = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
`;

export const StyledPerPageSelect = styled(StyledSelect)`
  width: 70px;
  height: 42px;
  .custom-select {
    line-height: 20px;
    border: 1px solid transparent;

    .rc-select-selector {
      min-height: 30px;
    }
    .rc-select-selection-item {
      padding: 10px 16px;
    }
    .option-select-item {
      width: 100%;
    }
    .rc-select-selection-search-input {
      cursor: pointer;
    }
    .rc-select-arrow {
      .rc-select-arrow-icon {
        top: 10px;
        right: 5px;
        &:before {
          font-size: 20px;
        }
      }
    }
  }
`;

export const StyledTotalItems = styled.div`
  p {
    color: ${({ theme }) => theme.neutral.whiteDarker};
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  @media screen and (max-width: 768px) {
    p {
      text-align: left;
    }
  }
`;

export const StyledFilterWrapper = styled.div`
  ${({ pt }) => pt && `padding-top: ${pt}px`};
  ${({ pb }) => pb && `padding-bottom: ${pb}px`};
  display: flex;
  justify-content: ${({ buttonAlign }) => buttonAlign ?? "flex-end"};
  gap: 16px;
  position: relative;

  .apply-button {
    span:before {
      font-size: 22px;
      color: ${({ theme }) => theme.primary.first};
    }
  }
`;

export const StyledFilterBackdrop = styled.div`
  width: 100vw;
  top: 0;
  height: 100vh;
  right: 0;
  position: fixed;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.2);
`;

export const StyledFilterBody = styled.div`
  width: 700px;
  padding: 24px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.mainTemplateBackgroundColor};
  display: flex;
  flex-direction: column;

  @keyframes showFilter {
    0% {
      opacity: 0;
      transform: translateY(-25px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  @media screen and (min-width: 991px) and (max-width: 1240px) {
    width: 500px;
  }
  @media screen and (max-width: 992px) {
    padding: 0;
    height: 100%;
    width: 100%;
  }
`;

export const StyledFilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledFilterFooter = styled.div`
  display: flex;
  flex: 1;
  margin-top: 20px;
  align-items: flex-end;
  justify-content: space-between;

  button {
    span:before {
      font-size: 22px;
      color: ${({ theme }) => theme.primary.first};
    }

    @media screen and (max-width: 992px) {
      width: calc(50% - 20px);
    }
    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 576px) {
    flex-direction: column;
    justify-content: flex-end;
    gap: 10px;
  }
`;

export const StyledFilterGroup = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.neutral.five};

  &:last-child {
    padding-top: 20px;
    border: none;
  }

  .filter-group {
    &__title {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    &__icon {
      width: 20px;
      height: 20px;
      font-size: 20px;
    }
  }
`;

export const StyledFilterGroupHead = styled.div`
  padding: 20px 0;
  color: ${({ theme }) => theme.neutral.third};
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease;

  .icon-chevron-up,
  .icon-chevron-down {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.primary.first};
    font-size: 20px;
  }

  &:hover {
    color: ${({ theme }) => theme.neutral.first};
  }

  span:before {
    font-size: 20px;
  }
`;

export const StyledFilterGroupBody = styled.div`
  ${({ hide }) => !hide && "height: 0"};
  ${({ hide }) => (!hide ? "display: none" : "display: flex")};
  ${({ singleView }) => singleView && "padding-top: 20px"};
    
  width: 100%;
  padding-bottom: 20px;
  gap: 16px;
  flex-wrap: wrap;
  
  & > div:nth-child(even),
  & > div:nth-child(odd) {
    width: calc(50% - 10px);
      
    @media screen and (min-width: 991px) and (max-width: 1240px) {
        width: 100%;
    }
 
    @media screen and (max-width: 764px) {
        width: 100%;
    }
  }
  
  & > div:nth-child(odd):last-child {
    width: 100%;
  }

  .input-group {
    margin-bottom: 0 !important;
    justify-content: flex-start;
  }
}
`;
