import { DateTime } from "luxon";
// import Moment from "react-moment";

//const format = "DD-MM-YYYY HH:mm:ss";

const TimestampToDateMoment = () => {
  // return <Moment format={format}>{new Date(str * 1000)}</Moment>;
};

const DateToFormat = () => {
  // return <Moment format={format}>{str}</Moment>;
};

const DateToTimestamp = (date) => {
  return date ? (Date.parse(date) / 1000).toString() : "";
};

const TimestampToDate = (timestamp) => {
  return DateTime.fromMillis(timestamp * 1000).toFormat("dd.MM.yyyy");
};

const TimestampToDateTime = (timestamp) => {
  return DateTime.fromMillis(timestamp * 1000).toFormat("dd.MM.yyyy HH:mm");
};

const TimestampToDateFilter = (timestamp) => {
  return timestamp ? new Date(timestamp * 1000) : "";
};

const DateToTimestampStart = (date) => {
  return date ? new Date(date).setHours(0, 0, 0) / 1000 : "";
};

const DateToTimestampEnd = (date) => {
  return date ? new Date(date).setHours(23, 59, 59) / 1000 : "";
};

const DateStringToDate = (date) => {
  if (!date || !date.length) {
    return null;
  }

  const parsedDate = DateTime.fromFormat(date, "dd.MM.yyyy");

  return parsedDate.toJSDate();
};

const convertToDOBNewFormat = (date) => {
  return DateTime.fromJSDate(new Date(date)).toFormat("dd.MM.yyyy");
};

export {
  convertToDOBNewFormat,
  DateStringToDate,
  DateToFormat,
  DateToTimestamp,
  DateToTimestampEnd,
  DateToTimestampStart,
  TimestampToDate,
  TimestampToDateFilter,
  TimestampToDateMoment,
  TimestampToDateTime,
};
