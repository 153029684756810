const parseSettings = (settingsArray) => {
  const result = settingsArray.reduce((acc, setting) => {
    acc[setting.name] = setting.value;
    return acc;
  }, {});

  return result;
};

const compareSettings = (defaultSettings, updatedSettings) => {
  const defaultSettingsMap = Object.fromEntries(
    defaultSettings.map(({ name, value }) => [name, value])
  );

  const updatedSettingsEntries = Array.isArray(updatedSettings)
    ? updatedSettings
        .filter(({ name, value }) => name && value)
        .map(({ name, value }) => [name, value])
    : Object.entries(updatedSettings).filter(([key, value]) => key && value);

  return updatedSettingsEntries.every(([key, value]) => defaultSettingsMap[key] === value);
};

const compareDocuments = (defaultSettings, updatedSettings) => {
  const defaultSettingsMap = Object.fromEntries(
    defaultSettings.map(({ id, name, value, isDisplayed }) => [id, { name, value, isDisplayed }])
  );

  const [existingDocuments, newDocuments] = updatedSettings.reduce(
    ([withId, withoutId], document) =>
      document.id ? [[...withId, document], withoutId] : [withId, [...withoutId, document]],
    [[], []]
  );

  const existingDocumentsMatching = existingDocuments.every(
    ({ id, name, value, isDisplayed }) =>
      defaultSettingsMap[id]?.name === name &&
      defaultSettingsMap[id]?.value === value &&
      defaultSettingsMap[id]?.isDisplayed === isDisplayed
  );

  const newDocumentsEmpty = newDocuments.every(({ name, value }) => !name || !value);

  return newDocuments.length > 0 ? newDocumentsEmpty : existingDocumentsMatching;
};

const compareSavedRequisites = (defaultRequisites, updatedRequisites) => {
  if (defaultRequisites.title !== updatedRequisites.title) {
    return false;
  }

  const defaultRequisitesMap = Object.fromEntries(
    defaultRequisites.requisitesAttributes.map(({ name, value }) => [name, value])
  );

  const updatedAttributesEntries = updatedRequisites.requisitesAttributes
    .filter(({ name, value }) => name && value)
    .map(({ name, value }) => [name, value]);

  return updatedAttributesEntries.every(([name, value]) => defaultRequisitesMap[name] === value);
};

const updateSettings = (defaultSettings, updatedSettings) => {
  const updatedSettingsMap = Object.fromEntries(
    updatedSettings.map((setting) => [setting.name, setting])
  );

  const changedSettings = defaultSettings
    .filter(
      (setting) =>
        Object.prototype.hasOwnProperty.call(updatedSettingsMap, setting.name) &&
        setting.value !== updatedSettingsMap[setting.name].value
    )
    .map(({ id, ...setting }) => ({
      ...setting,
      value: updatedSettingsMap[setting.name].value,
    }));

  const newSettings = updatedSettings.filter(
    (setting) => !defaultSettings.some((defaultSetting) => defaultSetting.name === setting.name)
  );

  return [...changedSettings, ...newSettings];
};

const updateDocuments = (defaultSettings, updatedSettings) => {
  const updatedSettingsMap = Object.fromEntries(
    updatedSettings.map((setting) => [setting.id, setting])
  );

  const changedSettings = defaultSettings
    .filter(
      (setting) =>
        Object.prototype.hasOwnProperty.call(updatedSettingsMap, setting.id) &&
        (setting.name !== updatedSettingsMap[setting.id].name ||
          setting.value !== updatedSettingsMap[setting.id].value ||
          setting.isDisplayed !== updatedSettingsMap[setting.id].isDisplayed)
    )
    .map((setting) => ({
      ...setting,
      name: updatedSettingsMap[setting.id].name,
      value: updatedSettingsMap[setting.id].value,
      isDisplayed: updatedSettingsMap[setting.id].isDisplayed,
    }));

  const newSettings = updatedSettings.filter((setting) => !setting.id);

  return [...changedSettings, ...newSettings];
};

const parseWidgetSettings = (settingsArray) => {
  const result = settingsArray.reduce((acc, setting) => {
    if (setting.name === "widgetLogo") {
      acc[setting.name] = setting.exchangerMediaObjectUrl;
    } else {
      acc[setting.name] = setting.value;
    }
    return acc;
  }, {});

  return result;
};

const removeWidgetLogoIfSame = (formData, initialData) => {
  const newObj = { ...formData };

  if (
    formData.widgetLogo &&
    initialData.widgetLogo &&
    formData.widgetLogo === initialData.widgetLogo
  ) {
    delete newObj.widgetLogo;
  }

  return newObj;
};

export {
  compareDocuments,
  compareSavedRequisites,
  compareSettings,
  parseSettings,
  parseWidgetSettings,
  removeWidgetLogoIfSame,
  updateDocuments,
  updateSettings,
};
