import { createSlice } from "@reduxjs/toolkit";

import {
  banClientsReducer,
  changeUserReferralInfoReducer,
  changeWidgetStatusExchangerReducer,
  fetchClientsDetailsReducer,
  fetchClientsReducer,
  getUserReferralInfoReducer,
  resetExchangerUserVerificationReducer,
} from "./clientsReducers";

const initialState = {
  clients: [],
  error: null,
  loading: true,
  clientDetails: {},
  referralInfo: {
    data: {},
    loading: true,
  },
  paginationInfo: {
    totalItems: null,
    totalPageCount: null,
    itemsPerPage: null,
  },
  banLoading: false,
  verificationLoading: false,
};

const clientsSlice = createSlice({
  name: "clientsSlice",
  initialState,
  reducers: {
    setPaginationInfo: (state, action) => {
      state.paginationInfo = action.payload;
    },
    clearStateClients: (state) => {
      state.clients = [];
      state.loading = true;
      state.error = null;
    },
    clearStateClientDetails: (state) => {
      state.loading = true;
      state.clientDetails = {};
    },
  },
  extraReducers: (builder) => {
    fetchClientsReducer(builder);
    fetchClientsDetailsReducer(builder);
    banClientsReducer(builder);
    changeWidgetStatusExchangerReducer(builder);
    getUserReferralInfoReducer(builder);
    changeUserReferralInfoReducer(builder);
    resetExchangerUserVerificationReducer(builder);
  },
});

export const { setPaginationInfo, clearStateClients, clearStateClientDetails } =
  clientsSlice.actions;

export default clientsSlice.reducer;
