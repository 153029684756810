import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const fetchExchangerConnections = createAsyncThunk(
  "dbExchangerConnectionsSlice/fetchExchangerConnections",
  async (filterUrl, thunkAPI) => {
    const url = filterUrl
      ? `/api/exchanger-database-connections${filterUrl}`
      : "/api/exchanger-database-connections";

    try {
      const response = await axiosInstance.get(url, userAuthenticationConfig());

      return {
        data: response.data["hydra:member"],
        paginationInfo: {
          totalPageCount: response.data["hydra:totalPageCount"],
          totalItems: response.data["hydra:totalItems"],
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);
const processExchangerConnectionStatus = createAsyncThunk(
  "dbExchangerConnectionsSlice/processExchangerConnectionStatus",
  async ({ id, status }, thunkAPI) => {
    try {
      const response = await axiosInstance.put(
        `/api/exchanger-database-connection/${id}/status`,
        { status },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);
export { fetchExchangerConnections, processExchangerConnectionStatus };
