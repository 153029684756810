import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { MoonLoader } from "react-spinners";

const Spinner = ({ size, color, display, margin }) => {
  const themeMode = useSelector((store) => store.theme.themeMode);

  const defaultColor = themeMode.primary.first;
  const override = css`
    display: ${display || "block"};
    margin: ${margin || "15px auto"};
  `;

  return <MoonLoader css={override} size={size} color={color ?? defaultColor} loading={true} />;
};

export default Spinner;
