import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const fetchEndpoints = createAsyncThunk("endpointSlice/fetchEndpoints", async (thunkAPI) => {
  try {
    const response = await axiosInstance("/api/endpoint/get-list", userAuthenticationConfig());

    return {
      data: response.data.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data);
  }
});

const fetchEndpointsByUser = createAsyncThunk(
  "endpointSlice/fetchEndpointsByUser",
  async (userId, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/endpoint-permissions/get-list-by-user/${userId}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const putEndpointsPermissions = createAsyncThunk(
  "endpointSlice/changeEndpointsPermissions",
  async (data, thunkAPI) => {
    try {
      const response = await axiosInstance.put(
        "/api/endpoint-permissions/edit-access",
        data,
        userAuthenticationConfig()
      );

      return {
        data: response.data.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

export { fetchEndpoints, fetchEndpointsByUser, putEndpointsPermissions };
