export const permissionManagerEndpoints = {
  PUT_EXCHANGER_CHANGE_AVATAR: { name: "put_exchanger_change_avatar", hasFullAccess: false },
  PUT_EXCHANGER_REMOVE_AVATAR: { name: "put_exchanger_remove_avatar", hasFullAccess: false },
  API_EXCHANGER_BALANCES_GET_COLLECTION: {
    name: "api_exchanger_balances_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_BALANCES_GET_ITEM: { name: "api_exchanger_balances_get_item", hasFullAccess: true },
  API_EXCHANGER_INVOICES_GET_COLLECTION: {
    name: "api_exchanger_invoices_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_INVOICES_GET_ITEM: { name: "api_exchanger_invoices_get_item", hasFullAccess: true },
  API_EXCHANGER_ORDERS_GET_COLLECTION: {
    name: "api_exchanger_orders_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_ORDERS_GET_ITEM: { name: "api_exchanger_orders_get_item", hasFullAccess: true },
  API_EXCHANGER_PAIR_UNITS_GET_COLLECTION: {
    name: "api_exchanger_pair_units_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_PAIR_UNITS_GET_ITEM: {
    name: "api_exchanger_pair_units_get_item",
    hasFullAccess: true,
  },
  API_EXCHANGER_PAIRS_GET_COLLECTION: {
    name: "api_exchanger_pairs_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_PAIRS_GET_ITEM: { name: "api_exchanger_pairs_get_item", hasFullAccess: true },
  API_EXCHANGER_USER_INFOS_GET_COLLECTION: {
    name: "api_exchanger_user_infos_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_USER_INFOS_GET_ITEM: {
    name: "api_exchanger_user_infos_get_item",
    hasFullAccess: true,
  },
  API_EXCHANGER_USERS_GET_COLLECTION: {
    name: "api_exchanger_users_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_USERS_GET_ITEM: { name: "api_exchanger_users_get_item", hasFullAccess: true },
  API_EXCHANGER_WIDGET_PAIRS_GET_COLLECTION: {
    name: "api_exchanger_widget_pairs_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_WIDGET_PAIRS_GET_ITEM: {
    name: "api_exchanger_widget_pairs_get_item",
    hasFullAccess: true,
  },
  API_EXCHANGER_WIDGETS_GET_COLLECTION: {
    name: "api_exchanger_widgets_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_WIDGETS_GET_ITEM: { name: "api_exchanger_widgets_get_item", hasFullAccess: true },
  API_EXCHANGER_WIDGET_SETTINGS_GET_COLLECTION: {
    name: "api_exchanger_widget_settings_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_WIDGET_SETTINGS_GET_ITEM: {
    name: "api_exchanger_widget_settings_get_item",
    hasFullAccess: true,
  },
  PUT_EXCHANGER_USERS_CHANGE_PASSWORD: {
    name: "put_exchanger_users_change_password",
    hasFullAccess: true,
  },
  GET_EXCHANGER_RATE_SERVICES: {
    name: "get_exchanger_rate_services",
    hasFullAccess: true,
  },
};
