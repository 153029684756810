import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const fetchExchangerDetails = createAsyncThunk(
  "exchangerDetailsSlice/fetchExchangerDetails",
  async (exchangerId, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/exchangers/${exchangerId}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const fetchExchangerServices = createAsyncThunk(
  "exchangerDetailsSlice/fetchExchangerServices",
  async (exchangerId, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/exchanger-connections?pagination=false&exchanger.id=${exchangerId}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data["hydra:member"],
        // paginationInfo: {
        //   totalPageCount: response.data["hydra:totalPageCount"],
        //   totalItems: response.data["hydra:totalItems"]
        // }
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeExchangerServiceStatus = createAsyncThunk(
  "exchangerDetailsSlice/changeExchangerServiceStatus",
  async ({ id, ...data }, thunkAPI) => {
    try {
      const response = await axiosInstance.put(
        `/api/exchanger-connections/change-enable-status/${id}`,
        { ...data },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeExchangerSettings = createAsyncThunk(
  "exchangerDetailsSlice/changeExchangerSettings",
  async (data, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/exchanger-settings",
        data,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const addExchangerCPConnection = createAsyncThunk(
  "exchangerDetailsSlice/addExchangerCPConnection",
  async ({ id, secret, projectId }, thunkAPI) => {
    try {
      const response = await axiosInstance.put(
        `/api/exchangers/${id}/connection-fields`,
        {
          connectionFieldsArray: {
            projectId,
            secret,
          },
        },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const getExchangerCPProjectId = createAsyncThunk(
  "exchangerDetailsSlice/getExchangerCPProjectId",
  async ({ id }, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/exchangers/${id}/get-project-id`,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const getExchangerSecretCPConnection = createAsyncThunk(
  "exchangerDetailsSlice/getExchangerSecretCPConnection",
  async ({ id, twoFa, callback }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        `/api/exchangers/${id}/connection-fields`,
        {
          twofa: twoFa,
        },
        userAuthenticationConfig()
      );
      callback();
      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const fillExchangerConnections = createAsyncThunk(
  "exchangerDetailsSlice/fillExchangerConnections",
  async ({ exchangerId, callback }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/fill-exchanger-connections",
        {
          exchangerId,
        },
        userAuthenticationConfig()
      );
      callback();
      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeWidgetStatusExchanger = createAsyncThunk(
  "exchangerDetailsSlice/changeWidgetStatusExchanger",
  async ({ id, widgetStatus }, thunkAPI) => {
    try {
      const response = await axiosInstance.patch(
        `/api/exchangers/${id}/widget-state`,
        { widgetStatus },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const putStatusExchanger = createAsyncThunk(
  "exchangersSlice/putStatusExchanger",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await axiosInstance.put(
        `/api/exchangers/${id}/status`,
        data,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const fetchCalculatorSettings = createAsyncThunk(
  "calculatorSettings/fetchCalculatorSettings",
  async (exchangerId, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/calculator-settings/${exchangerId}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeCalculatorSettings = createAsyncThunk(
  "calculatorSettings/changeCalculatorSettings",
  async (data, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/calculator-settings",
        data,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const deleteCalculatorSettings = createAsyncThunk(
  "calculatorSettings/deleteCalculatorSettings",
  async ({ id, exchangerId }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        `/api/remove-calculator-settings/${id}`,
        { exchangerId },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
        id,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

export {
  addExchangerCPConnection,
  changeCalculatorSettings,
  changeExchangerServiceStatus,
  changeExchangerSettings,
  changeWidgetStatusExchanger,
  deleteCalculatorSettings,
  fetchCalculatorSettings,
  fetchExchangerDetails,
  fetchExchangerServices,
  fillExchangerConnections,
  getExchangerCPProjectId,
  getExchangerSecretCPConnection,
  putStatusExchanger,
};
