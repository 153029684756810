import getUserInfo from "../getUserInfo";

const checkPermission = (permissions) => {
  const userPermissions = getUserInfo()?.endpoints;

  if (!userPermissions) {
    return false;
  }

  return permissions.some((perm) =>
    userPermissions.some(
      (userPermission) =>
        userPermission.name === perm.name && userPermission.hasFullAccess === perm.hasFullAccess
    )
  );
};

const checkPermissionByName = (permissions) => {
  const userPermissions = getUserInfo()?.endpoints;

  if (!userPermissions) {
    return false;
  }

  return permissions.some((perm) =>
    userPermissions.some((userPermission) => userPermission.name === perm.name)
  );
};

export { checkPermission, checkPermissionByName };
