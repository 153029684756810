import styled from "styled-components";

import { StyledSpan } from "../../styles/styledTypography";

export const StyledLogoWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  transition: all 0.3s ease;

  ${StyledSpan} {
    max-width: 128px;
    color: ${({ theme }) => theme.neutral.white};
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
  }

  @media screen and (max-width: 992px) {
    //gap: 8px;
    ${StyledSpan} {
      display: none;
    }
`;

export const StyledLogoIcon = styled.div`
  height: 36px;
  z-index: 1;
  transition: all 0.3s ease;

  img {
    height: 36px;
    object-fit: contain;
    object-position: center;
  }

  @media screen and (max-width: 992px) {
    height: 30px;

    img {
      height: 30px;
    }
  }
`;
