import i18n from "i18next";
import React from "react";
import { useSelector } from "react-redux";
import { useMatch } from "react-router-dom";

import { defaultLanguage } from "../../../utils/consts";
import withPermissionCheckComponent from "../../../utils/customHOC/withPermissionCheckComponent";
import Can from "../../elements/can/Can";
import { StyledSpan, StyledTextRegular } from "../../styles/styledTypography";
import SidebarItemSubLink from "./SidebarItemSubLink";
import { StyledSidebarItem, StyledSidebarLink, StyledSidebarSubLinkList } from "./styledSidebar";

const heightSubItem = 40;

const SidebarItem = ({
  animateOpen,
  setAnimateOpen,
  collapsed,
  as,
  to,
  icon,
  title,
  subLink,
  subRelation,
  toggled,
  setToggled,
  t,
  permissions,
  perform,
}) => {
  const userState = useSelector((store) => store.user);

  const { user } = userState;

  const language = i18n.language === defaultLanguage ? "" : i18n.language;

  const match = useMatch({
    path: subRelation ? `${language}/crm/${subRelation}/*` : `${language}${to}`,
  });

  const sidebarItemFactory = (subLink) => {
    if (perform) {
      return (
        <Can
          role={user.roles}
          perform={perform}
          yes={() => (
            <StyledSidebarItem onClick={() => setToggled(false)}>
              <StyledSidebarLink
                as={as}
                to={to}
                className={match && "active"}
                collapsed={collapsed}
              >
                <StyledSpan fontSize="24" className={icon} />
                <StyledTextRegular>{t(title)}</StyledTextRegular>
              </StyledSidebarLink>
            </StyledSidebarItem>
          )}
        />
      );
    }

    if (subLink) {
      const lengthWithPerm = subLink.filter((item) => {
        if (!item?.permission) {
          return item;
        }

        if (item?.permission.some((route) => permissions.includes(route))) {
          return item;
        }
      }).length;

      return (
        <StyledSidebarItem onClick={() => setAnimateOpen(true)}>
          <StyledSidebarLink
            as={as}
            to={to}
            className={match && "active_sub-link"}
            collapsed={collapsed}
          >
            <StyledSpan fontSize="24" className={`icon-${icon}`} />
            <StyledTextRegular>{t(title)}</StyledTextRegular>
          </StyledSidebarLink>

          <StyledSidebarSubLinkList
            animateOpen={animateOpen}
            isOpen={match && (!collapsed || toggled)}
            bodyHeight={lengthWithPerm * heightSubItem}
          >
            {subLink.map((subLink) => {
              const SidebarItemSubLinkWithPerm = withPermissionCheckComponent(
                SidebarItemSubLink,
                subLink?.permission
              );

              return (
                <SidebarItemSubLinkWithPerm
                  language={language}
                  as={as}
                  key={subLink.path}
                  path={subLink.path}
                  title={t(`${subRelation}SubLink.${subLink.title}`)}
                />
              );
            })}
          </StyledSidebarSubLinkList>
        </StyledSidebarItem>
      );
    }

    return (
      <StyledSidebarItem onClick={() => setToggled(false)}>
        <StyledSidebarLink as={as} to={to} className={match && "active"} collapsed={collapsed}>
          <StyledSpan fontSize="24" className={icon} />
          <StyledTextRegular>{t(title)}</StyledTextRegular>
        </StyledSidebarLink>
      </StyledSidebarItem>
    );
  };

  return sidebarItemFactory(subLink);
};

export default SidebarItem;
