import dark from "./dark";
import light from "./light";

export const lightTheme = {
  ...light,
};

export const darkTheme = {
  ...dark,
};
