import GeneralPageSkeleton from "../../components/skeletons/GeneralPageSkeleton";
import GoodsPageSkeleton from "../../components/skeletons/pages/GoobsPageSkeleton";
import { pageSkeletonKeys } from "../../utils/consts";

const suspenseFallbackFactory = (pageComponentPath) => {
  switch (pageComponentPath) {
    case pageSkeletonKeys.GOODS_PAGE: {
      return <GoodsPageSkeleton />;
    }
    default: {
      return <GeneralPageSkeleton />;
      //todo commented until find more best decision
      // return <PageSpinner />;
    }
  }
};

export { suspenseFallbackFactory };
