import { createSlice } from "@reduxjs/toolkit";

import { fetchBalancesReducer, fetchExchangerBalancesReducer } from "./balancesReducers";

const initialState = {
  balances: [],
  error: null,
  loading: true,
  paginationInfo: {
    totalItems: null,
    totalPageCount: null,
    itemsPerPage: null,
  },
};

export const SLICE_NAME = "balancesSlice";

const balancesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setPaginationInfo: (state, action) => {
      state.paginationInfo = action.payload;
    },
    clearStateBalances: (state) => {
      state.balances = [];
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    fetchBalancesReducer(builder);
    fetchExchangerBalancesReducer(builder);
  },
});

export const { setPaginationInfo, clearStateBalances } = balancesSlice.actions;

export default balancesSlice.reducer;
