import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const fetchExchangerUserVerification = createAsyncThunk(
  "documents/fetchVerification",
  async (thunkAPI) => {
    try {
      const response = await axiosInstance(
        "/api/exchanger-user-verifications",
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const createExchangerUserVerification = createAsyncThunk(
  "documents/createExchangerUserVerification",
  async (data, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/exchanger-user-verifications/verify",
        data,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const fetchExchangerUserVerifiedVerificationSchemas = createAsyncThunk(
  "documents/fetchExchangerUserVerifiedVerificationSchemas",
  async (userId, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/exchanger-user-verifications/verified-verification-schemas/${userId}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

export {
  createExchangerUserVerification,
  fetchExchangerUserVerification,
  fetchExchangerUserVerifiedVerificationSchemas,
};
